/*Header*/
.header {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  max-width: 1200px;
  /* margin: 0 auto; */
  margin: 0px 6.8%;
}

.first-section-header {
  display: flex;
    justify-content: flex-start;
    align-items: center;
    /* max-width: 367px; */
    max-width: 330px;
    cursor: pointer;
    height: 54px !important;
    width: 90% !important;
    padding-top: 0px;
}

.first-section-header input {
  height: 35px;
  width: auto;
  min-width: 240px;
}
.contractjob span.title-h {
  margin-bottom: 0 !important;
}

.contractjob .ant-dropdown-link.ant-dropdown-trigger {
  vertical-align: middle;
  color: #1f6f99}
.post-description p {
  font-size: 12px;
  font-weight: 500;
}
.ratejob span.title-h {
  margin-bottom: 0px !important;
}

.ratejob a.ant-dropdown-link.ant-dropdown-trigger {
  vertical-align: middle;
  color: #1f6f99}
.jobform {
  border: 1px solid #cec7c7;
  background-color: #ffff;
  max-width: 100%;
  padding: 30px 45px !important;
}

.post-heading h2 {
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 20px;
  margin-top: 30px;
}
span.title-h {
  font-weight: 700;
  color: #000;
}
.jobform textarea.ant-input {
  min-height: 90px !important;
}
.c-box {
  /* position: absolute !important; */
  /* bottom: 0 !important; */
  /* padding-left: 9px !important; */
}
/* span.tag-cu {
  padding: 5px 13px !important;
} */
.my-input::before {
  visibility: hidden !important;
}
.Postjob {
  background-color: #EFF2F6;
  box-shadow: 0 0 2px 0px #adadad;
  min-height: calc(100vh - 115px);
  padding-bottom: 7% !important;
}
.ant-menu-horizontal > .ant-menu-item > a:only-child {
  font-weight: 500;
}

.ant-menu-item .anticon {
  display: none;
}

.ant-menu-horizontal > .ant-menu-item {
  border-bottom: 0 !important;
  padding: 0 14px;
}

.ant-menu-horizontal > .ant-menu-item {
  padding-right: 0 !important;
  padding-left: 25px;
}
.ratejob {
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  padding: 6px;
}
.contractjob {
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  padding: 6px;
}
.ratejob span.anticon.anticon-down {
  float: right;
  margin-top: 5px;
  color: #a7a1a1;
}
.contractjob span.anticon.anticon-down {
  float: right;
  margin-top: 5px;
  color: #a7a1a1;
}
.post-description img {
  width: 36px;
  height: 36px;
  border: 2px solid #333;
  padding: 5px;
  margin-right: 10px;
  border-radius: 8px;
}
.ant-input:focus,
.ant-input-focused {
  border-color: #d9d9d9 !important;
  -webkit-box-shadow: none !important;
  box-shadow: none;
}
p {
  font-size: 16px !important;
  color: #808080;
  margin-bottom: 5px;
}
.img-de {
  display: flex;
  margin-bottom: 10px;
}

.img-de h2 {
  font-weight: 700;
}
ul.items {
  padding-left: 0;
  list-style: none;
  display: flex;
  margin-bottom: 10;
}
.space .title-h {
  display: inline-block;
  vertical-align: middle;
}
ul.items li {
  padding-right: 20px;
  position: relative;
  font-size: 13px;
  color: #ababab;
  font-weight: 600px;
}

ul.items li:after {
  position: absolute;
  content: ">";
  top: 50%;
  transform: translateY(-50%);
  right: 7px;
  font-weight: 700;
}

ul.items li:last-child:after {
  display: none;
}
.ant-menu-horizontal {
  border-bottom: 0 !important;
}
.ant-menu-horizontal > .ant-menu-item > a {
  color: #1f6f99}
.post-description img {
  /* width: 45px; */
  /* height: auto; */
  width: 34px;
  height: 36px;
  border: 2px solid #333;
  padding: 5px;
  margin-right: 10px;
  border-radius: 8px;
}
.space {
  margin-bottom: 15px;
  position: relative;
}
span.tag-cu {
  font-size: 12px;
  color: #074369!important;
  border: 1px solid #074369!important;
  background-color: #fff!important;
  border-radius: 20px;
  padding: 3px 12px;
  margin-right: 10px;
  font-weight: 600;
}
.ant-input-affix-wrapper:hover {
  border-color:#1f6f99;
  border-right-width: 1px !important;
}
.ant-btn-link {
  color: #1f6f99!important;
 
}
.ftext {
  text-align: center;
  padding: 17px;
  box-shadow: 0 0 1px 0px #adadad;
  color: #adadad;
  font-size: 13px;
}
.submitbtn {
  margin-top: 20px;
  background-color: #1f6f99 !important;
}
.my-input input {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.pointer {
  cursor: pointer;
}
.c-box.fss-12 {
  flex-wrap: wrap;
}

.fss-12 span.tag-cu {
  white-space: nowrap;
}
h4 {
  font-weight: 700;
  color: #000;
  
}
.first-section-header img:first-child {
  position: relative;
  /* top: -4px; */
  top: -8px;
  margin-right: 2px;
}
.activepagination:hover {
  color: #06b9b9;
}
.activepagination {
  color: #078d9a;
}
.ant-input-affix-wrapper{
    position: relative;
    width: 85%;
    min-width: 0;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    line-height: 1.5715;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    display: flex;
    margin: 6% auto 0 !important;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

@media only screen and (max-width: 768px){
  .ant-input-affix-wrapper{
    position: relative;
    width: 81% !important;
    min-width: 0;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    line-height: 1.5715;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    display: flex;
    margin: 2% auto 0 !important;
    justify-content: center;
    align-items: center;
    margin-left: 7% !important;
  }
}