.cl-btn button {
  border: none;
  box-shadow: none;
  background: transparent;
  color: #1f6f99;
  font-weight: 600;
}

.cl-btn {
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
}
.joining {
  text-align: end;
}
button.ant-btn.morebtn {
  border: none;
  outline: none;
  background: transparent;
  padding: 0;
  margin: 0;
}

span.per-23 {
  font-weight: 500;
  color: #000;
}
.jobprofile {
  /* border: 1px solid #dcd6d6; */
  
}
.design-sp {
  /* margin-top: 35px; */
}
.page-33.fitl-5 {
  display: flex;
  justify-content: flex-end;
  
  padding: 30px;
}

.page-33.fitl-5 button {
  cursor: pointer;
}

.page-33 ul.ant-pagination.mini li.ant-pagination-options {
  position: absolute !important;
  right: 100%;
  transform: translateX(calc(100% + 218px));
}
.page-33 {
  display: flex;
  justify-content: flex-end;
  padding-top: 100px;
}
.cl-btn button {
  border: none;
  box-shadow: none;
  background: transparent;
  color: #074369;
  font-weight: 600;
}

.cl-btn {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
.joining {
  text-align: end;
}
button.ant-btn.morebtn {
  border: none;
  outline: none;
  background: transparent;
  padding: 0;
  margin: 0;
}
.design-sp span {
  font-size: 12px;
  /* white-space: nowrap; */
    display: inline-block;
    margin-top: 5px;
}
span.per-23 {
  font-weight: 500;
  color: #000;
}
.jobprofile {
  /* border: 1px solid #dcd6d6; */
}
  
.design-sp {
  /* margin-top: 30px; */
}
.detail-66 p {
  margin-bottom: 15px;
}
.detail-66 span {
  border: 1px solid;
  border-radius: 20px;
  padding: 2px 10px;
  margin-right: 10px;
  color: #1f6f99;
}
.detail-66 span.anticon.anticon-close {
  border: none;
  font-size: 11px;
  padding: 0;
  margin: 0;
}
.linkbtn svg {
  font-size: 12px;
}
button.linkbtn {
  border: none;
  background: transparent;
  outline: none;
  text-transform: uppercase;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected{

  border-bottom: none;
}
.ant-modal.email-34 {
  width: 700px!important;
}
.form-control {
  padding: 10px;
  width: 100%!important;
}

.form-control select,.form-control input, .form-control textarea {
  width: 100%!important;
  max-width: 100%!important;
  min-width: 100%!important;
}

.form-control .ant-select {
  width: 100%!important;
}
.email-34 span.ant-modal-close-x {
  display: none;
}
.j-34 {
  display: flex;
  justify-content: space-between;
}
.savebtn.app {
  border: none;
  box-shadow: none;
  background-color: #074369;
  color: #fff;
  line-height: 1.5715;
  position: relative;
  display: inline-block;
  font-weight: 500;
  white-space: nowrap;
  padding: 5px 25px;
  text-align: center;
  border-radius: 4px;
  cursor: pointer;
}
span.jik {
  color: #666666;
  font-weight: 400;
}

.jik img{
  height: 21px;
  width: 21px;
  margin-left: 2%;
}

.color-dark-blue{
  color: #074369;
}

.duration{
  color: #383838;
}

@media only screen and (max-width: 768px){
  .jik img{
    margin-left: 0;
  }
}








/***************Email Modal****************/

.email-34 textarea:focus {
  outline: none;
}

.email-34 .ant-btn.ant-btn-primary {
  /* background-color: #1f6f99;
  color: #fff;
  border-color: #1f6f99; */
  background-color: #074369;
  font-weight: bold;
  color: #fff;
  border-radius: 3px;
  border-color: #074369;
}

.ant-modal-footer .ant-btn{
  font-weight: bold;
  border-color: #666666;
}
.ant-modal-footer .ant-btn:hover {
  border-color: #074369 !important;
  /* color: #074369 !important; */
}
.email-34 .ant-select-arrow {
 
  top: 49%;
  right: 20px;
  
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color:  #1f6f99;
  border-right-width: 1px !important;
}
.email-34 button.ant-btn:hover {
  color: #1f6f99;
  border-color: #1f6f99;
}
.email-34 .ant-btn.ant-btn-primary:hover{
  color: #fff;
}
.email-34 .ant-select-selector:hover {
  border-color: #1f6f99!important;
}

.designation.msgbtn {
  border: none;
  box-shadow: none;
  /* background: transparent; */
  color: #1f6f99;
  font-weight: 600;
  color: #fff; 
  background-color: #074369; 
  border-radius: 4px; 
  margin-top: 3%
}
.designation.msgbtn:hover {
  color: #fff; 
  background-color: #074369; 
  border-radius: 4px; 
}
/* .cl-btn .msgbtn:hover {
  color: #1f6f99;
} */

/* 
@media only screen and (max-width){
  
} */