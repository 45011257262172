button.primary.dd-12 {
    background-color: #fff!important;
    border-color: #074369 !important;
    border-radius: 20px;
    color: #074369!important;
    padding: 5px 32px!important;
    height: auto !important;
    font-weight: 600;
    cursor: pointer;
}
button.primary:hover{

    background-color: #1f6f99;
    color: #fff;


}
/* .first-section-header img {
    height: 54px;
    width: 60%;
    padding-top: 7px;
} */
.header-logo{
    height: 54px !important;
    width: 90% !important;
    padding-top: 7px;
    width: 250px !important;
    /* margin-left: 12%; */
    margin-top: 2%;
    height: 34px !important;
}

li.ant-menu-item.ant-menu-item-only-child a {
    color: #074369;
}
.first-section-header img:first-child {
    width: 35px;
}
.ant-menu-horizontal > .ant-menu-item a:hover {
    color: #074369;
}
button.primary.dd-12:hover {
    background-color: #1f6f99!important;
    color: #fff!important;
}
