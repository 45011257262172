.subscription h2 {
    margin-bottom: 30px;
}
.subs-check {
    margin-bottom: 30px;
}
.button-des {
    background-color: #1f6f99;
    border-color: #1f6f99;
    color:#fff;
    padding: 10px;
    cursor:pointer;
}
.button-des:focus {
    outline: none;
    border: none;
}
.subs-check {
    display: flex;
    flex-flow: column;
}
button.button-des {
    outline: none;
    border: none;
}



.subscription .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0px;
}