@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap);
body {
  margin: 0;
  font-family: "Poppins", sans-serif !important;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.contractjob a.ant-dropdown-link.ant-dropdown-trigger {
  margin-left: 11px;
}
.ratejob a.ant-dropdown-link.ant-dropdown-trigger {
  margin-left: 11px;
}
.contractjob {
  margin-top: 12px;
}

button.Button__button___vS7Mv {
  color: #fff;
  background: #1f6f99;
  background-color: #1f6f99!important ;
}
:root {
  --amplify-primary-color:#1f6f99 ;
  --amplify-primary-tint: #ff7359;
  --amplify-primary-shade: #e0573e;
}

.d-flex {
  display: flex;
}

.justify-content-center {
  justify-content: center;
}
button.primary {
    background-color: #fff;
    border: 1px solid #1f6f99;
    outline: none;
    padding: 4px 10px;
    color: #1f6f99;
    min-height: auto!important;
    line-height: initial;
    border-radius: 20px;
    font-weight: 600;
    cursor: pointer;
}
button.ant-btn.homesign.ant-btn-primary {
    background-color: #fff!important;
    border-color: #1f6f99!important;
    border-radius: 20px;
    color: #1f6f99!important;
    padding: 5px 40px!important;
    height: auto !important;
    font-weight: 600;
}
button.ant-btn.homesign.ant-btn-primary:hover{
    background-color: #1f6f99!important;
    color: #fff !important;
}
button.primary:hover{

    background-color: #1f6f99;
    color: #fff;


}
.first-section-header img {
    width: 175px;
    height: auto;
    /* background-color: pink; */
    position: relative !important;
    /* right: 5%; */
}

li.ant-menu-item.ant-menu-item-only-child a {
    color: #1f6f99;
}
.first-section-header img:first-child {
    width: 35px;
}
.ant-menu-horizontal > .ant-menu-item a:hover {
    color: #1b8bc5;
}
.cl-btn button {
  border: none;
  box-shadow: none;
  background: transparent;
  color: #1f6f99;
  font-weight: 600;
}

.cl-btn {
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
}
.joining {
  text-align: end;
}
button.ant-btn.morebtn {
  border: none;
  outline: none;
  background: transparent;
  padding: 0;
  margin: 0;
}

span.per-23 {
  font-weight: 500;
  color: #000;
}
.jobprofile {
  /* border: 1px solid #dcd6d6; */
  
}
.design-sp {
  /* margin-top: 35px; */
}
.page-33.fitl-5 {
  display: flex;
  justify-content: flex-end;
  
  padding: 30px;
}

.page-33.fitl-5 button {
  cursor: pointer;
}

.page-33 ul.ant-pagination.mini li.ant-pagination-options {
  position: absolute !important;
  right: 100%;
  -webkit-transform: translateX(calc(100% + 218px));
          transform: translateX(calc(100% + 218px));
}
.page-33 {
  display: flex;
  justify-content: flex-end;
  padding-top: 100px;
}
.cl-btn button {
  border: none;
  box-shadow: none;
  background: transparent;
  color: #074369;
  font-weight: 600;
}

.cl-btn {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
.joining {
  text-align: end;
}
button.ant-btn.morebtn {
  border: none;
  outline: none;
  background: transparent;
  padding: 0;
  margin: 0;
}
.design-sp span {
  font-size: 12px;
  /* white-space: nowrap; */
    display: inline-block;
    margin-top: 5px;
}
span.per-23 {
  font-weight: 500;
  color: #000;
}
.jobprofile {
  /* border: 1px solid #dcd6d6; */
}
  
.design-sp {
  /* margin-top: 30px; */
}
.detail-66 p {
  margin-bottom: 15px;
}
.detail-66 span {
  border: 1px solid;
  border-radius: 20px;
  padding: 2px 10px;
  margin-right: 10px;
  color: #1f6f99;
}
.detail-66 span.anticon.anticon-close {
  border: none;
  font-size: 11px;
  padding: 0;
  margin: 0;
}
.linkbtn svg {
  font-size: 12px;
}
button.linkbtn {
  border: none;
  background: transparent;
  outline: none;
  text-transform: uppercase;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected{

  border-bottom: none;
}
.ant-modal.email-34 {
  width: 700px!important;
}
.form-control {
  padding: 10px;
  width: 100%!important;
}

.form-control select,.form-control input, .form-control textarea {
  width: 100%!important;
  max-width: 100%!important;
  min-width: 100%!important;
}

.form-control .ant-select {
  width: 100%!important;
}
.email-34 span.ant-modal-close-x {
  display: none;
}
.j-34 {
  display: flex;
  justify-content: space-between;
}
.savebtn.app {
  border: none;
  box-shadow: none;
  background-color: #074369;
  color: #fff;
  line-height: 1.5715;
  position: relative;
  display: inline-block;
  font-weight: 500;
  white-space: nowrap;
  padding: 5px 25px;
  text-align: center;
  border-radius: 4px;
  cursor: pointer;
}
span.jik {
  color: #666666;
  font-weight: 400;
}

.jik img{
  height: 21px;
  width: 21px;
  margin-left: 2%;
}

.color-dark-blue{
  color: #074369;
}

.duration{
  color: #383838;
}

@media only screen and (max-width: 768px){
  .jik img{
    margin-left: 0;
  }
}








/***************Email Modal****************/

.email-34 textarea:focus {
  outline: none;
}

.email-34 .ant-btn.ant-btn-primary {
  /* background-color: #1f6f99;
  color: #fff;
  border-color: #1f6f99; */
  background-color: #074369;
  font-weight: bold;
  color: #fff;
  border-radius: 3px;
  border-color: #074369;
}

.ant-modal-footer .ant-btn{
  font-weight: bold;
  border-color: #666666;
}
.ant-modal-footer .ant-btn:hover {
  border-color: #074369 !important;
  /* color: #074369 !important; */
}
.email-34 .ant-select-arrow {
 
  top: 49%;
  right: 20px;
  
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color:  #1f6f99;
  border-right-width: 1px !important;
}
.email-34 button.ant-btn:hover {
  color: #1f6f99;
  border-color: #1f6f99;
}
.email-34 .ant-btn.ant-btn-primary:hover{
  color: #fff;
}
.email-34 .ant-select-selector:hover {
  border-color: #1f6f99!important;
}

.designation.msgbtn {
  border: none;
  box-shadow: none;
  /* background: transparent; */
  color: #1f6f99;
  font-weight: 600;
  color: #fff; 
  background-color: #074369; 
  border-radius: 4px; 
  margin-top: 3%
}
.designation.msgbtn:hover {
  color: #fff; 
  background-color: #074369; 
  border-radius: 4px; 
}
/* .cl-btn .msgbtn:hover {
  color: #1f6f99;
} */

/* 
@media only screen and (max-width){
  
} */
.list-col-ui .heading {
  margin-bottom: 15px;
  display: block;
  font-weight: 600;
}

.list-col-ui .input-group-prepend .input-group-text {
  padding: 0;
  margin-right: 10px;
  position: relative;
}
.list-col-ui .input-group-prepend .input-group-text input[type="checkbox"] {
  -webkit-appearance: none;
  width: 15px;
  height: 15px;
  border: 1px solid gray;
}

.list-col-ui
  .input-group-prepend
  .input-group-text
  input[type="checkbox"]:checked:after {
  opacity: 1;
}

.list-col-ui
  .input-group-prepend
  .input-group-text
  input[type="checkbox"]:after {
  position: absolute;
  content: "\2713";
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  opacity: 0;
}

.list-col-ui .input-group-prepend .input-group-text {
  border: none;
  background-color: transparent;
}

.list-col-ui label {
  margin-bottom: 0px;
}

.search-ico svg {
  width: 22px;
  stroke: #ffffff;
  fill: #ffffff;
}
button.search-ico.btn.btn-primary {
  border-radius: 0px 4px 4px 0px;
  padding: 5px 10px;
}

.cu-filter {
  margin-bottom: 1.25rem;
  border: 1px solid #00000021;
  border-radius: 2px;
}
.sert {
  margin-top: -31px;

  flex-wrap: wrap;
}
.ant-slider {
  padding: 0;
  margin: 0;
}
.ant-slider:hover .ant-slider-rail {
  background-color: #074369;
}


.ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused {
  border-color: #074369;
}
.ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
  border-color: #074369;
  color: #074369;
}
.ant-slider-track:hover{
  color: #074369;
}
.ant-slider-handle{
  background-color: #074369 !important;
}
.ant-slider-handle-dragging{
  color: #074369;
}
.ant-slider-rail {
  background-color:#666666 !important;
}
.ant-slider .ant-slider-track {
  background-color: #074369;
}
.ant-slider-handle-dragging.ant-slider-handle-dragging.ant-slider-handle-dragging{
  box-shadow: 0 0 0 5px rgba(7, 67, 105, 0.3);
}

/* .ant-slider-handle:focus{
  border-color: #074369;
} */
.tag-cu .anticon-close-circle {
  position: absolute;
  top: -8px;
  right: -8px;
}

ul.list {
  margin: 0;
  background: #ffffff;
  list-style-type: none;
  margin-top: -11px;
  position: relative;
  width: 100%;
  z-index: 99;
  height: 115px;
  overflow-x: auto;
  border: 1px solid #d8d8d8;
  padding: 5px 20px;
}

ul.list li {
  padding: 6px 0px;
  font-size: 15px;
  border-top: 1px solid #d8d8d8;
  margin-top: -1px;
  font-weight: 700;
  color: #222;
  cursor: pointer;
}

ul.list li:first-child {
  border-top: none;
}

.c-box {
  display: flex;
  z-index: 99;
  width: 100%;
  margin: 10px 0px;
}

.my-input {
  position: relative;
}

.list-type {
  margin-top: -10px;
  position: relative;
  width: 100%;
  z-index: 99;
  height: 183px;
  overflow-x: auto;
  display: block;
  background: #efefef;
}

.list-type span {
  display: block;
  padding: 10px 20px;
  border-top: 1px solid #d8d8d8;
  margin-top: -1px;
  font-size: 16px;
}

.my-input::before {
  content: "";
  background: url(/static/media/search.a05b6011.png);
  width: 20px;
  height: 20px;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 999;
  position: absolute;
  display: block;
  top: 6px;
  left: 10px;
}

.my-input .close {
  content: "";
  background: url(/static/media/close.dfa8895d.png);
  width: 20px;
  height: 20px;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 999;
  position: absolute;
  display: block;
  top: 6px;
  right: 10px;
  cursor: pointer;
}

.my-input input {
  padding-left: 40px;
  padding-right: 40px;
}

.ant-checkbox-wrapper {
  margin-bottom: 10px;
}
.work-auth {
  margin-top: 21px;
}
.work-auth inputlabel {
  margin-right: 2px;
}
.skil-34 span {
  border: 1px solid #1f6f99;
  border-radius: 20px;
  padding: 2px 3px;
  margin-right: 7px;
  color: #fff;
  background-color: #1f6f99;
}

.search-box button.ant-btn {
  background-color: #1f6f99;
  color: #fff;
  border-color:  #1f6f99;
}
.ant-input:hover {
  border-color:  #1f6f99!important;
  border-right-width: 1px !important;
}

.gud-34 {
  /* border-top: 1px solid rgb(217, 217, 217); */
  width:100%;
}
label.chck.ant-checkbox-wrapper {
  color: #a5a3a7;
}
span.heading {
  margin-bottom: 9px !important;
}
.df-4 {
  margin-top: 8px;
  margin-bottom: 0.5rem;
}
span.anticon.anticon-plus-circle {
  font-size: 20px;
}
a.ant-dropdown-link.adt-23.ant-dropdown-trigger svg {
  /* margin-left: 135px; */
  /* position: absolute;
  top: 5px;
  right: 10px; */
}

button.ant-btn.skill span.anticon.anticon-plus-circle {
  position: relative;
  left: 5px;
}

button.ant-btn.skill {
  padding: 5px 10px;
}
.gud-34 label.ant-checkbox-wrapper {
  margin-bottom: 0;
}
.range-33 {
  display: flex;
  justify-content: space-between;
  color: #666666;
}
.work-auth .ant-col.ant-col-24 {
  margin-bottom: 0px !important;
}
.filters-result {
  margin-top: 1px;
  /* padding-left: 13px;
  padding-right: 13px; */
  padding: 0;
}
.sert {
  margin-top: -31px;

  flex-wrap: wrap;
}
.fer-5 span.tag-cu {
    font-weight: 600;
    background-color: #D3D3D3 !important;
    border-radius: 2.4px;
    border: none!important;
    color: #383838!important;
    font-size: 12px;
    display: inline-flex;
    cursor: default;
    padding: 10px!important;
    line-height: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    margin-bottom: 10px;
}
.fer-5span.tag-cu {
  padding: 10px 15px !important;
}
.fer-5 span.tag-cu:hover {
  background-color: #b2b2b2!important;
  cursor: pointer;
}
.sert span {
  font-weight: 500;
}
.f42-contract a.ant-dropdown-link.adt-23.ant-dropdown-trigger {
  color: #999999;
  margin-left: 7%;
  border: 1px solid #D3D3D3;
  border-radius: 3px;
  padding: 2% 3%;
}

.dropdown-icon{
  position: absolute;
  top: 5px;
  right: 10px;
}

@media only screen and (max-width: 768px) {
    .dropdown-icon{
      position: absolute !important;
      /* top: 10px !important; */
      right: 10px !important;
    }
}

.job--title{
  font-style: normal !important;
  font-weight: normal !important;
  line-height: 20px;
  color: #383838 !important;
  padding-bottom: 2%;
  border-bottom: 0.76px solid #D3D3D3;
  margin-right: 7%;
  margin-left: 7%;
}

.clear-filter{
  font-style: normal;
font-weight: bold;
font-size: 14px;
line-height: 17px;
color: #6E6E6E;
}

.apply-filter{
  font-style: normal;
  font-weight: bold !important;
  font-size: 15px;
  line-height: 20px;
  color: #074369;
}

.apply-filter-mobile{
  font-style: normal;
  font-weight: bold !important;
  font-size: 13px;
  line-height: 20px;
  color: #FFFFFF !important;
  border: none;
  box-shadow: none;
  font-weight: 300;
  padding: 3px 12px;
  border-radius: 4px;
  height: 2.5rem;
}

.clear-filter-mobile{
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #074369;
}

.town23--mobile{
  display: none;
}
/* for mobile styles */

@media only screen and (max-width: 768px) {
  .jobprofile{
    width: 90%;
    margin: auto;
  }
  .savebtn.app{
    padding: .3125rem 1.25rem;
    border-radius: .375rem;
    right: 5%;
    margin-left: 5%;
  }
  .job-rate{
    position: relative;
    top: 11%;
  }
  .town23--desktop{
    display: none;
  }
  .town23--mobile{
    display: block;
  }
  .f42-contract a.ant-dropdown-link.adt-23.ant-dropdown-trigger {
    color: #999999;
    margin-left: 7%;
    border: 1px solid #D3D3D3;
    border-radius: 3px;
    padding: 1% 3%;
    width:81% !important;
  }
}
.candi-head {
  display: flex;
  justify-content: space-between;
}
.content-4 {
  display: flex;
  margin-bottom: 15px;
}

.content-4 > p {
  margin-right: 10px;
  color: #000;
}
.details-7 {
  text-align: end;
}
.displaybtn button {
  border: none;
  box-shadow: none;
  padding: 0px 10px 0px 0px;
  margin-top: 8px;
  color: #1f6f99;
}

/* .location p{
  display: flex;
  justify-content: space-between;
} */

.location{
  position: relative;
  bottom: 4px;
}
.location-duration{
  display: flex;
  justify-content: space-between;
}

.location-text{
  display: inline-block;
  width: 50%;
}

.skit {
  display: flex;
  margin-top: 11px;
  margin-bottom: 1.5rem;
}
.fer-5 {
  display: flex;
  flex-wrap: wrap;
  max-width: 635px;
  width: 100%;
  margin: 0px auto;
  padding-left: 7%;
}
.state-33 .anticon.anticon-environment {
  margin-right: 10px;
  font-size: 14px;
}
.detail-66 p {
  margin-bottom: 15px;
}
button.ant-btn.sst-4.ant-btn-primary {
  background-color: #ffffff;
  color:#1f6f99;
  border-color: #ffffff;
  height: 40px;
  padding: 0px 20px;
  box-shadow: 0 2px 10px 1px rgb(142 147 148 / 40%), 0 1px 2px rgb(101 108 109 / 25%);
}
button.ant-btn.ant-btn-ghost {
  background-color: #a5a3a7;
  border-color: #a5a3a7;
  height: 40px;
  padding: 0 26px;
}

.displaybtn {
  margin-top: 12px;
}
.detail-66 span {
  border: 1px solid;
  border-radius: 20px;
  padding: 2px 12px;
  margin-right: 10px;
  color: #757575;
  font-weight: bold;
}
button.ant-btn.ant-btn-ghost {
  background-color: #a5a3a7;
  border-color: #a5a3a7;
}
.search-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.guest-34 ol {
  padding: 0px 0px 0px 10px;
}
.filt-btn button {
  background-color: transparent !important;
  color: #1f6f99!important;
  border: none;
  box-shadow: none;
  font-weight: 300;
}
.filt-btn-mobile button {
  background-color: #074369 !important;
  color: #FFFFFF !important;
  border: none;
  box-shadow: none;
  font-weight: 300;
  border-radius: 4px;
}

/* a:hover {
  color: #000;
}
a{
  color:#000;
} */
button.ant-btn.search {
  margin-left: 0px;
  background-color: #074369;
  color: #fff;
  border-radius: 4px;
  padding: 3px 20px;
  font-weight: 600;
}
.heading df-4 a.ant-dropdown-link.adt-23.ant-dropdown-trigger {
  color: #000;
}
.ant-slider-track {
 
  background-color: #074369;
  
}
.ant-slider-handle.ant-tooltip-open,.ant-slider-handle.ant-tooltip-open:focus {
  border-color: #074369;
}
.ant-slider-handle {
  border: solid 2px #074369;
}
.ant-dropdown-menu-item:hover, .ant-dropdown-menu-submenu-title:hover {
  background-color: #f5f5f5;
  color: #1f6f99;
}
span.tag-cu {
  font-size: 12px;
  color: #1f6f99!important;
  border: 1px solid #1f6f99!important;
  background-color: #fff!important;
  border-radius: 20px;
  padding: 3px 12px;
  margin-right: 10px;
  font-weight: 600;
}
button.ant-btn.sst-4.ant-btn-primary:hover {
  box-shadow: 0 2px 10px 1px rgb(54 72 76 / 40%), 0 1px 2px rgb(68 82 84 / 25%);
}
button.ant-btn.ant-btn-ghost:hover {
  box-shadow: 0 1px 6px rgb(88 101 103 / 35%);
}

.search-input {
  display: flex;
  align-items: center;
  width: 70%;
  padding-top: 0.6%;
}

.details-7 span {
  font-weight: 600;
}
.guest-34 {
  border: 1px solid #dcd6d6;
  background-color: #fff;
  padding: 10px 17px;
}
.post-6 span {
  font-size: 11px;
  border: 1px solid #1f6f99;
  border-radius: 20px;
  padding: 2px 5px;
  margin-right: 9px;
  color: #1f6f99;
  background-color: #fff;
}
.post-6 {
  margin-bottom: 20px;
}
.active-45 .ant-select-show-search {
  width: 300px !important;
  margin-bottom: 40px;
}
.guest-34 p {
  margin-bottom: 15px;
  margin-top: 10px;
}
.ant-card.bod-45.ant-card-bordered {
  border: 1px solid #dcd6d6;
}
.delete-link {
  display: flex;
  justify-content: space-between;
}

.delete-link button {
  border: none;
  box-shadow: none;
  background-color: #fff;
  outline: none;
}
button.vie-34 {
  color: #1f6f99;
}

.bod-45 .ant-card-body {
  padding: 24px 35px;
}
/* .search-item {
  
    margin-bottom: 50px;
    margin-top: -38px;
} */
.search-item h4 {
  line-height: 25px;
  margin-right: 20px;
}
.viewcandidate h2 {
  font-weight: 700;
  font-size: 25px;
  margin-top: 20px;
}
.visi-67 {
  transition: none !important;
  overflow: hidden !important;
}
.visi-67 * {
  transition: none !important;
}
.candidateprofile h2 {
  margin-top: 20px;
}
.ant-row.ant-form-item.chlist span {
  color: #e0d8d8 !important;
  font-weight: 400;
}
.recent-search ul {
  padding: 0;
  list-style: none;
}
.jobs ul {
  padding: 0;
  list-style: none;
}
.jobsearch h2 {
  margin-top: 20px;
  margin-bottom: 30px;
}
.jobs p {
  color: #1f6f99;
  font-weight: 600;
  font-size: 15px!important;
}
.search-item.pop-sp {
  margin-top: -59px;
}
.jobsearch {
  margin-bottom: 30px;
  padding-top: 25px;
}
.search-121 {
  box-shadow: 0 0 2px 0px #adadad;
  background-color: #EFF2F6;
}

.job--title{
  font-style: normal !important;
  font-weight: normal !important;
  line-height: 20px;
  color: #383838 !important;
  padding-bottom: 2%;
  border-bottom: 0.76px solid #D3D3D3;
  margin-right: 7%;
  margin-left: 7%;
}

.filter-img{
  display: inline-block;
  margin-left: 7%;
  margin-top: 11%;
}

.filter-block--display{
  padding-top: 9%;
  padding-left: 7%;
}

.filter-block--text{
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  display: inline-block;
  margin-left: 4%;
  color: #999999;
}

.jobs-Filters{
  margin-top: 13%;
  background-color: #fff;
  border-radius: 10px;
}

.skit--mobile{
  display: none;
}

.sert-mobile{
  display: none !important;
}

.slider-auto{
  margin: 0px 5%;
  padding: 21px 10px;
}

.filter-result-block{
  background-color: #074369; 
  height: 4.6875rem; 
  width: 100%; 
  padding-left: 7%; 
  padding-top: 3%;
  display: none !important;
}
.filter-result-text{
  font-size: 16px;
  color: white;
  font-weight: bold;
  margin-left: 3%;
}

.sert-desktop{
  margin-bottom: -10%;
}

@media only screen and (max-width: 768px){
  .jobsearch{
    margin-left: 2%;
  }
  .filter-result-block{
    display: block !important;
  }
  .job-heading{
    margin-left: 5%;
    font-size: 1.125rem;
  }
  .location-text{
    font-size: .75rem;
    margin-left: 2%;
  }
  .location-duration{
    display: block;
  }
  .duration{
    font-size: .75rem;
    color: #666666;
    display: block;
  }
  button.ant-btn.search {
    height: 34px !important;
    font-size: 12px;
    padding: 3px 13px;
    position: absolute;
    right: 19%;
  }
  .search-input{
    width: 80%;
    margin-bottom: 15px;
    margin: auto;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .search-box{
    margin: 0 6% 0 5%;
  }
  .search-input input{
    width: 83%;
  }
  /* job filter section hiding on mobile view*/
  .jobs-Filters{
    /* display: none; */
  }
  .skit--mobile{
    display: block;
  }
  .fer-5{
    margin-left: 5%;
    padding: 0;
  }
  .filter-block--display{
    display: none;
  }
  .job--title{
    border-bottom: none;
    font-weight: bold !important;
    color: #666666 !important;
  }
  .sert-desktop{
    display: none !important;
  }
  .sert-mobile{
    display: flex !important; 
  }
  .slider-auto{
    margin-right: 10%;
  }
}
.profile-skill svg {
    margin-top: 5px;
}
button.ant-btn.editbtn {
    padding: 4px 35px;
    height: 38px;
    /* background: #1f6f99!important; */
    background: #074369!important;
    border-color: #074369!important;
    border-radius: 4px;
}
.candidate-form {
    border: 1px solid #cec7c7;
    background-color: #ffff;
    max-width: 100%;
    padding: 30px 45px !important;
    border-radius: 10px;
}
.pace {
    margin-bottom: 20px;
}

.em-23 span {
    display: flex;
}
.title-name {
    font-weight: 600;
    color: #000;
}
.contractor h2 {
    margin-bottom: 20px;
}
.set-space.ant-breadcrumb {
    margin-bottom: 15px;
}
.profile-skill {
    display: flex;
}
.profile-skillspan.tag-cu {
    padding: 10px 15px !important;
}
span.ant-breadcrumb-link {
    cursor: pointer;
}

.candidate-form {
    border: 1px solid #cec7c7;
    background-color: #ffff;
    max-width: 100%;
    padding: 30px 45px !important;
}
.title-name {
    font-weight: 600;
    color: #000;
}
.wrk-45 {
    border: 1px solid #d9d9d9;
    padding: 6px 11px;
}
.w-12 span {
    display: flex;
}
.prefer {
    display: flex;
}
.will {
    margin-top: 10px;
}
.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #1f6f99;
    border-color: #1f6f99;
}
.count span {
    display: flex;
}
.contractor{
    padding-top: 3%;
}
.contractor h2 {
    margin-bottom: 20px;
}
.set-space {
    margin-bottom: 15px;
}
.pace.count svg {
    margin-top: 5px;
}
.d74e {
    display: flex;
    justify-content: space-between;
    padding: 3px 10px;
    border: 1px solid #d9d9d9;
}

.d74e label.d5-name {
    background:#1f6f99;
    color: #ffffff;
    border-radius: 2px;
    padding: 3px 10px;
}
.wrk-45 a.ant-dropdown-link.ant-dropdown-trigger {
    width: 100%;
    display: block;
    color: #bfbfbf;
   
}

.wrk-45 a.ant-dropdown-link.ant-dropdown-trigger span.anticon.anticon-down {
    position: absolute;
    right: 10px;
    top: 70%;
}

.cons-desired a.ant-dropdown-link.ant-dropdown-trigger {
    width: 100%;
    display: block;
    color: #bfbfbf;
    
}
.cons-desired a.ant-dropdown-link.ant-dropdown-trigger span.anticon.anticon-down{

    position: absolute;
    right: 10px;
    top: 35%;
}
.pace {
    margin-bottom: 20px;
}
.w-12.pace {
    position: relative;
}

.em-23 span {
    display: flex;
}
.resume-33.pace {
    display: flex;
    flex-direction: column;
}
.sign-in {
    display: flex;
    justify-content: center;
    padding-top: 50px;
  }
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
   
    border-bottom:none!important;
}
button.ant-btn.concan-btn.ant-btn-primary {
    background-color: #074369;
    border-color: #074369;
    padding: 4px 35px;
    height: 38px;
    border-radius: 4px;
}

.dh41 .count span {
    display: inline-block!important;
}
.contract-12 span.tag-cu {
    white-space: nowrap;
    margin-bottom: 5px;
}
.contract-12 .c-box {
   
    flex-wrap: wrap;
}
/* .pace.count {
    display: flex;
    flex-direction: column;
} */
/* 
.resume-33.pace inputlabel {
    margin-right: 5px;
} */

/* input[type="file"] {
    width: 45%;
} */
.color-dark-blue{
    color: #074369;
}
.list-df p {
    color: #000;
    font-weight: 500;
}
.designation.dms {
    height: auto;
    margin-bottom: 7px;
}
.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 80px 0px 80px;
  }
  .heading-text {
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
  }
  span.doc-3 {
    display: inline-block;
    vertical-align: middle;
}

span.doc-3 button {
    line-height: normal;
    margin: 1px!important;
}
  .heading-text h2 {
    font-weight: 700;
    font-size: 25px;
  }
  .cus-33 .container {
    padding: 50px 0px;
  }
  .designation svg {
    font-size: 12px;
    color: #808080;
  }
  .heading-text span {
    color:#1f6f99;
    font-weight: 700;
    font-size: 25px;
  }
  button.ant-btn.ered.ant-btn-link {
    /* color: #1f6f99; */
    color: #074369 !important;
    margin-top: 5px;
  }
  .candidate-22 {
    display: flex;
    justify-content: space-between;
  }
  .designation {
    border: 1px solid #dcd6d6;
    background-color: #fff;
    padding: 20px 20px;
    height: 100%;
    border-radius: 10px;
  }

  .candidate-22 span {
    font-weight: 700;
    color: #000;
    white-space: nowrap;
  }
  .designation p {
    /* margin: 10px 0px; */
    word-break: break-word;
  }
  .candidate-22 h4 {
    font-weight: 700;
    color: #000;
  }
  span.state-33 {
    font-size: 12px;
    color: #808080;
    
  }
  .view-link {
    display: flex;
    justify-content: space-between;
  }
  .view-link button {
    border: none;
    background-color: #fff;
    color:#1f6f99;
    font-weight: 400;
    outline: none;
    cursor: pointer;
    padding: 0px;
  }
  .page-33 {
    display: flex;
    justify-content: flex-end;
    padding-top: 100px;
  }
  button.ant-btn.post-23 {
    background-color: #1f6f99 !important;
    height: 41px;
    padding: 0px 23px;
    border-color: #1f6f99;
  }
  
  .page-33 ul.ant-pagination.mini li.ant-pagination-options {
    float: left;
    position: relative;
  }
  
  .page-33 ul.ant-pagination.mini li.ant-pagination-options:after {
    content: "Candidates per page";
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    right: calc(100% + 10px);
    white-space: nowrap;
  }
  button.linkbtn {
    border: none;
    background: transparent;
    outline: none;
    text-transform: uppercase;
  }
  .designation ol {
    padding-left: 10px;
  }
  .page-33.fitl-5.chgd {
    border: none!important;
    background-color: #EFF2F6!important;
}
.design-sp.detail-66.dss-2 {
  margin-top: 15px;
}

.designation.dms {
  height: 100%;
  }
  

  button.ant-btn.ant-btn-primary.ant-btn-sm {
    background-color: #1f6f99;
    border-color: #1f6f99;
}

button.ant-btn.ant-btn-sm:hover {
  border-color: #1f6f99;
  
}

.color-dark-blue{
  color: #074369;
}

.load-more-wrapper{
  margin-top: 30px;
  margin-bottom: 30px;
  text-align: -webkit-center;
}
.massage-load-more{
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #335A8D; 
  width: -webkit-fit-content; 
  width: -moz-fit-content; 
  width: fit-content; 
  height: 23px;
  border-bottom: 1px solid #335A8D;   
}

@media only screen and (max-width: 762px) {
  .space-br{
    display: none;
  }
}
.list-df p {
    color: #000;
    font-weight: 500;
}
.designation.dms {
    height: auto;
    margin-bottom: 7px;
}
.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 80px 0px 80px;
  }
  .heading-text {
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
  }
  span.doc-3 {
    display: inline-block;
    vertical-align: middle;
}

span.doc-3 button {
    line-height: normal;
    margin: 1px!important;
}
  .heading-text h2 {
    font-weight: 700;
    font-size: 25px;
  }
  .cus-33 .container {
    padding: 50px 0px;
  }
  .designation svg {
    font-size: 12px;
    color: #808080;
  }
  .heading-text span {
    color:#1f6f99;
    font-weight: 700;
    font-size: 25px;
  }
  button.ant-btn.ered.ant-btn-link {
    /* color: #1f6f99; */
    color: #074369 !important;
    margin-top: 5px;
  }
  .candidate-22 {
    display: flex;
    justify-content: space-between;
  }
  .designation {
    border: 1px solid #dcd6d6;
    background-color: #fff;
    padding: 20px 20px;
    height: 100%;
    border-radius: 10px;
  }

  .candidate-22 span {
    font-weight: 700;
    color: #000;
    white-space: nowrap;
  }
  .designation p {
    /* margin: 10px 0px; */
    word-break: break-word;
  }
  .candidate-22 h4 {
    font-weight: 700;
    color: #000;
  }
  span.state-33 {
    font-size: 12px;
    color: #808080;
    
  }
  .view-link {
    display: flex;
    justify-content: space-between;
  }
  .view-link button {
    border: none;
    background-color: #fff;
    color:#1f6f99;
    font-weight: 400;
    outline: none;
    cursor: pointer;
    padding: 0px;
  }
  .page-33 {
    display: flex;
    justify-content: flex-end;
    padding-top: 100px;
  }
  button.ant-btn.post-23 {
    background-color: #1f6f99 !important;
    height: 41px;
    padding: 0px 23px;
    border-color: #1f6f99;
  }
  
  .page-33 ul.ant-pagination.mini li.ant-pagination-options {
    float: left;
    position: relative;
  }
  
  .page-33 ul.ant-pagination.mini li.ant-pagination-options:after {
    content: "Candidates per page";
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    right: calc(100% + 10px);
    white-space: nowrap;
  }
  button.linkbtn {
    border: none;
    background: transparent;
    outline: none;
    text-transform: uppercase;
  }
  .designation ol {
    padding-left: 10px;
  }
  .page-33.fitl-5.chgd {
    border: none!important;
    background-color: #EFF2F6!important;
}
.design-sp.detail-66.dss-2 {
  margin-top: 15px;
}

.designation.dms {
  height: 100%;
  }
  

  button.ant-btn.ant-btn-primary.ant-btn-sm {
    background-color: #1f6f99;
    border-color: #1f6f99;
}

button.ant-btn.ant-btn-sm:hover {
  border-color: #1f6f99;
  
}

.color-dark-blue{
  color: #074369;
}

.load-more-wrapper{
  margin-top: 30px;
  margin-bottom: 30px;
  text-align: -webkit-center;
}
.massage-load-more{
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #335A8D; 
  width: -webkit-fit-content; 
  width: -moz-fit-content; 
  width: fit-content; 
  height: 23px;
  border-bottom: 1px solid #335A8D;   
}

@media only screen and (max-width: 762px) {
  .space-br{
    display: none;
  }
}
body {
    margin: 0;
    padding: 0;
  }
  /************************** Navbar **************************/
  
  .navbar {
    padding: 0.625px 0 .9375rem 0;
    margin-bottom: 2%;
  }
  .navbar img {
    /* width: 13.75rem;
    height: 2.216875rem; */
     margin-left: 34%;
    margin-top: 1%;
  }
  
  .navbar-item {
    /* font-size: 1.125rem; */
    font-size: 14px;
    font-weight: 500;
    line-height: 1.125rem;
    display: inline-block;
    padding-top: 1.875rem;
    color: #074369;
    cursor: pointer;
  }
  @media (min-width: 1200px){
    .navbar .ant-col-xl-3 {
        display: block;
        flex: 0 0 12.5%;
        max-width: 8.5%;
    }
  }
  
  .navbar-item--active {
    font-size: 1.125rem;
    line-height: 1.125rem;
    display: inline-block;
    margin-top: 25px;
    color: #074369;
    cursor: pointer;
    border: 3px solid #074369;
    padding: 4% 15%;
    border-radius: 6px;
  }
  
  .navbar-list {
    font-size: 1rem;
    line-height: 1.1875rem;
    color: #062d45;
    padding: 5%;
    cursor: pointer;
  }
  .navbar-list li {
    padding: 2% 0;
  }
  .ant-drawer-body {
    padding: 0 !important;
    height: 50vh !important;
    width: 50vw !important;
  }
  .ant-drawer-body li {
    display: block;
  }
  
  .card-body {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    position: absolute;
    top: 30%;
    left: 14%;
  }
  @media only screen and (max-width: 992px) {
    .listItem {
      display: none;
    }
    .navbar-btn {
      display: inline !important;
      background-color: white;
      border: 0;
      padding: 0 0;
      position: absolute;
      top: 20px;
      right: -1.25rem;
      cursor: pointer;
      outline: none;
    }
  }
  @media only screen and (min-width: 992px) {
    .navbar-btn {
      display: none !important;
    }
  }
  @media only screen and (max-width: 600px) {
  .navbar{
    padding-top: 0 !important;
  }
  .navbar img{
    width: 12.375rem !important;
    height: 2.216875rem !important;
    margin-left: 8% !important;
    /* margin-top: 9% !important; */
  }
  .navbar-item--active {
    font-size: .818181rem;
    line-height: 1rem;
  }
  
  }
  
  @media only screen and (max-width: 767px) {
    .card-body {
      top: 48%;
      left: 64%;
      width: 36%;
      /* top: 108px;
    left: 207px; */
      /* width: 200px; */
    }
  }
  /************************** Navbar End **************************/
  
  /************************** Header **************************/
  
  @media only screen and (max-width: 600px) {
    .header-home {
      text-align: center;
    }
  }
  
  
  .header-home {
    padding: 6% 0;
    padding-bottom: 0;
    font-style: normal;
    margin: 2% 7% 10%;
    margin-right: 0%;
  }
  
  .header-content {
    margin: 1.25rem;
    margin-right: 12%;
  }
  
  .header-heading {
    font-weight: bold;
    font-size: 2.5rem;
    line-height: 3.0625rem;
    color: #074369;
    margin-bottom: 10%;
    padding-right: 16%
  }
  .header-para {
    font-weight: normal;
    font-size: 1.5rem;
    line-height: 2.125rem;
    color: #666666;
    margin-top: 7%;
    margin-bottom: 5%;
    padding: 2% 23% 2% 0;
  }
  .header-image {
    width: 90% !important;
    height: auto !important;
  }
  
  .header-btn{
    font-size: 1rem;
    line-height: 1.1875rem;
    position: relative;
    right: 3px;
    width: 12.3rem;
    height: 3.6rem;
    margin-bottom: 15%;
    background: #074369;
    border: 2.6px solid #ffffff;
    box-sizing: border-box;
    border-radius: 0.5rem;
    cursor: pointer;
    font-style: normal;
    font-weight: bold;
    text-align: center;
    color: #ffffff;
  }
  
  @media only screen and (max-width: 600px) {
    .header-home {
      text-align: center;
    }
    #header-desktop--view {
      display: none;
    }
    .header-btn{
      width: 60% !important;
      height: 3.0rem !important;

    }
    .btn {
      width: 54% !important;
    }
    .header-image {
    }
    .header-heading {
      font-size: 1.5rem;
      line-height: 1.8125rem;
      padding: 0%;
      margin: 2px;
    }
    .header-para {
      font-size: 14px;
      line-height: 22px;
      padding: 0%;
    }
  }
  @media only screen and (min-width: 600px) {
    #header-mobile--view {
      display: none;
    }
  }
  
  /************************** Header End **************************/
  
  /************************** Features **************************/
  .features-heading {
    font-style: normal;
    font-weight: bold;
    font-size: 2.5rem;
    line-height: 3.0625rem;
    color: #074369;
    text-align: center;
    margin-top: 5%;
  }
  .features-para {
    font-style: normal;
    font-weight: normal;
    font-size: 1.5rem;
    line-height: 2.125rem;
    text-align: center;
    color: #666666;
    padding: 0 22%;
  }
  .features-para--mobile{
    display: none;
  }
  @media only screen and (max-width: 600px) {
    .features-heading,
    .features-para {
      text-align: left;
      padding: 0;
      padding-left: 4%;
    }
    .features-col {
      padding: 2% 0% !important;
    }
    .features-heading {
      color: #062d45;
      font-size: 1.5rem;
      line-height: 1.8125rem;
      padding-top: 15%;
    }
    .features-para {
      font-size: .875rem;
      line-height: 1.375rem;
      padding-right: 6%;
    }
    .features-content {
      padding-right: 5%;
    }
    .feature-block--heading{
      font-size: 1.25rem !important;
      line-height: 1.5rem !important;
      
    }
    .features-para--mobile{
      display: block;
    }
    .features-para--desktop{
      display: none;
    }
  }
  .feature-img {
    height: 1.875rem;
    width: 1.875rem;
    margin-bottom: 4%;
  }
  .feature-block--heading {
    font-size: 1.5rem;
    line-height: 1.625rem;
    font-style: normal;
    font-weight: normal;
    margin-bottom: 0;
    border-bottom: 1px solid #9f9f9f;
    color: #2e2e2e !important;
    display: inline-block;
    padding-bottom: 3%;
    word-spacing: 30000px;
  }
  .feature-block--para {
    margin-top: 10%;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #2e2e2e !important;
    padding-right: 6%;
  }
  .features-col {
    padding: 2% 5%;
  }
  .container {
    padding: 2% 5%;
  }
  /************************** Features End **************************/
  
  /************************** IT section **************************/
  .it {
    padding: 6% 0;
    padding-bottom: 0;
    font-style: normal;
    margin: 8% 7%;
  }
  
  .it-content {
    margin: 2.25rem;
    margin-right: 12%;
  }
  
  .it-heading {
    font-weight: bold;
    font-size: 2.5rem;
    line-height: 3.0625rem;
    color: #074369;
    margin-bottom: 10%;
    padding-right: 14%;
  }
  .it-para {
    font-weight: normal;
    font-size: 1.5rem;
    line-height: 2.125rem;
    color: #666666;
    margin-top: 7%;
    margin-bottom: 5%;
    padding: 2% 2% 2% 0;
  }
  @media only screen and (max-width: 752px){
    .it-image {
      width: 60% !important;
      height: auto !important;
    }
  }
  /* .div{
    padding-left: 25%;
  } */
  .it-image {
      width: 80%;
      height: auto;
  }
  
  @media only screen and (max-width: 1320px) {
    .div{
      padding-left: 0% !important;
      text-align: center !important;
    }
   
    .div-woman{
      padding-left: 5% !important;
      margin: auto;
    }
    .card-body{
      position: absolute;
      right: 25% !important;
      bottom: 5% !important;
      /* left: -44px; */
      left: -1rem;
    }
  }
  @media only screen and( max-width: 768 px){
    .it-image {
      width: 60% !important;
      height: auto !important;
    }
  }
  
  .div-woman{
    padding-left: 25%;
  }
  
  @media only screen and (max-width: 600px) {
    .it {
      text-align: center;
    }
   
    .div-woman{
      padding: 0 !important;
      text-align: center !important;
      margin: auto !important;
    }
    .div {
      padding-left: 1%;
      text-align: center !important;
    }
    .it-desktop--view {
      display: none;
    }
    .btn {
      font-size: 1rem !important;
      line-height: 1.1875rem !important;
    }
    .it-image {
      height: 270px !important;
      width: 257px !important;
    }
    .it-mobile--view {
      margin: 10% 0;
      width: 70% !important;
    }
    .it-heading {
      font-weight: bold;
      font-size: 1.375rem !important;
      line-height: 1.8125rem !important;
      color: #074369;
      margin-bottom: 10%;
      padding-right: 0%;
      }
    
    .it-para {
      font-size: .875rem;
      line-height: 1.5rem;
      text-align: center;
    }
  }
  @media only screen and (min-width: 600px) {
    .it-mobile--view {
      display: none;
    }
  }
  /************************** IT End **************************/
  
  /************************** ReasonSection **************************/
  .reason-section {
    background-color: #074369;
  }
  
  .reason-section-content {
    padding: 3% 5% 5% 0;
    color: #ffffff;
  }
  .reason-section-heading {
    font-style: normal;
    font-weight: bold;
    font-size: 2.3rem;
    line-height: 3.0625rem;
    letter-spacing: 1px;
    color: #ffffff;
    margin-bottom: 8%;
  }
  .reason-section-heading--mobile-view{
    display: none;
  }
  
  .tick-img {
    height: 5%;
    width: 5%;
    margin-top: -1%;
  }
  dl {
    font-style: normal;
    font-weight: normal;
  }
  dt {
    display: inline;
    font-weight: bold;
    font-size: 1.3rem;
    line-height: 1.8125rem;
    padding-left: 1.8%;
  }
  dd {
    font-size: 1.25rem;
    line-height: 1.75rem;
    /* padding: 1% 10% 5% 7%; */
    padding: 1% 5% 5% 7%;
  }
  .reason-section-btn {
    font-style: normal;
    font-weight: bold;
    font-size: 1.25rem;
    line-height: 1.5rem;
    text-align: center;
    color: #ffffff;
    background: #074369;
    border: 2.8px solid #ffffff;
    box-sizing: border-box;
    border-radius: 3px;
    cursor: pointer;
    padding: 2% 6%;
  }
  .reason-section-btn--mobile{
    display: none;   
  }
  @media only screen and (max-width: 600px) {
    .reason-section-images {
      height: 100vh;
    }
    .reason-section-heading {
      text-align: center;
      font-size: 1.5rem;
      line-height: 1.8125rem;
    }
    dt {
      font-size: 1.25rem;
      line-height: 1.5rem;
      padding-left: 4%;
    }
    dd {
      font-style: normal;
      font-weight: normal;
      font-size: 1.125rem;
      line-height: 1.5rem;
      padding-left: 13%;
    }
    .reason-block{
      margin: 5% 0;
    }
    .reason-section-content {
      padding: 5%;
    }
    .head {
      display: none;
    }
    .tick-img {
      height: 1.75rem;
      width: 1.75rem;
      margin-top: -1%;
    }
    .reason-section-heading--mobile-view{
      display: block;
      padding-top: 20%;
      margin-bottom: -17%;
    }
    .reason-section-heading--desktop-view{
      display: none;
    }
    .reason-section-btn--mobile{
     display: block;
     font-size: 1rem;
     line-height: 1.8125rem;
     margin: auto;
     border: 2px solid #ffffff;
     width: 60%;
     margin-bottom: 15%;
    }
    .reason-section-btn--desktop{
      display: none;
    }
    .grid-image{
      width: 100%;
      height: auto;
    }
    @media only screen and (max-width: 600px) {
      .grid-image {
        width: 80%;
      }
    }
  }
  /************************** ReasonSection End **************************/
  
  /************************** Review Section **************************/
  .reviews {
    padding: 8%;
  }
  .reviews-heading {
    text-align: center;
    font-weight: bold;
    font-size: 2.5rem;
    line-height: 3.0625rem;
    color: #074369;
    margin-bottom: 3%;
  }
  .card {
    box-shadow: 0px 0px 19px 2px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 4%;
    margin: 4%;
  }
  .card-title {
    font-size: 1.5rem;
    line-height: 1.5rem;
    margin-top: 3%;
  }
  .card-subtitle {
    font-size: 1rem;
    line-height: 1.625rem;
  }
  .card-company {
    font-size: 1rem;
    line-height: 1.625rem;
    position: absolute;
    top: 9.8rem;
  }
  .card-text {
    font-size: 1rem;
    line-height: 1.625rem;
    color: #2e2e2e;
  }
  .stars img {
    padding: 10% 3% 5% 0;
  }
  
  .center-image {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  @media only screen and (max-width: 600px) {
    .reviews {
      display: none;
    }
  }
  
  
  /************************** Review section end **************************/
  
  /************************** Map Section **************************/
  
  .map {
    padding: 6% 0;
    padding-bottom: 0;
    font-style: normal;
    margin: 0 7%;
    margin-bottom: 4%;
  }
  
  .map-content {
    margin: 1.25rem;
    margin-right: 12%;
  }
  
  .map-heading {
    font-weight: bold;
    font-size: 2.5rem;
    line-height: 3.0625rem;
    color: #074369;
    margin-bottom: 10%;
  }
  .map-para {
    font-weight: normal;
    font-size: 1.5rem;
    line-height: 2.125rem;
    color: #666666;
    margin-top: 7%;
    margin-bottom: 5%;
  }
  .div {
    padding-left: 25%;
  }
  .map-image {
    height: auto!important;
    width: 100% !important;
  }
  
  .btn {
    font-size: 1.25rem;
    line-height: 1.5rem;
    position: relative;
    right: 3px;
    width: 15rem;
    height: 3.5rem;
    margin-bottom: 15%;
    background: #074369;
    border: 2.6px solid #ffffff;
    box-sizing: border-box;
    border-radius: 6px;
    cursor: pointer;
    font-style: normal;
    font-weight: bold;
    text-align: center;
    color: #ffffff;
  }
  
  .map-btn--mobile{
      font-size: 1rem;
      line-height: 1.1875rem;
      position: relative;
      right: 3px;
      width: 15rem;
      height: 3.5rem;
      margin-bottom: 15%;
      background: #074369;
      border: 2.6px solid #ffffff;
      box-sizing: border-box;
      border-radius: 6px;
      cursor: pointer;
      font-style: normal;
      font-weight: bold;
      text-align: center;
      color: #ffffff;
  }
  
  @media only screen and (max-width: 600px) {
    .map {
      text-align: center;
      margin-top: 11%; 
      margin-bottom: 11%; 
    }
    .div {
      padding-left: 0%;
    }
    #map-desktop--view {
      display: none;
    }
    .map-image {
        width: 100% !important;
        height: auto !important;
    }
    .map-heading {
      font-size: 1.375rem;
      line-height: 1.8125rem;
      margin-top: 13%;
    }
    .map-para {
      font-size: 1rem;
      line-height: 1.625rem;
    }
    .map-btn--mobile{
      width: 60% !important;
    }
  }
  @media only screen and (min-width: 600px) {
    #map-mobile--view {
      display: none;
    }
  }
  
  /************************** Map section end **************************/
  
  /************************** Footer **************************/
  
  .footer {
    text-align: center;
    /* font-family: Proxima Nova; */
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.625rem;
    padding: 2% 0 1% 0;
  }
  .footer-img {
    width: 14.9375rem;
    height: 2.4668rem;
  }
  .footer-text {
    padding-top: 2%;
    color: #333333;
  }
  .footer-word {
    color: #074369;
  }
  
  /* footer for mobile screen */
  @media only screen and (max-width: 768px){
    .footer-word{
      margin-top: -1%;
      margin-bottom: 5%
    }
    .footer{
      padding-top: 8%;
    }
  }
  /************************** Footer End **************************/
  
button.primary.dd-12 {
    background-color: #fff!important;
    border-color: #074369 !important;
    border-radius: 20px;
    color: #074369!important;
    padding: 5px 32px!important;
    height: auto !important;
    font-weight: 600;
    cursor: pointer;
}
button.primary:hover{

    background-color: #1f6f99;
    color: #fff;


}
/* .first-section-header img {
    height: 54px;
    width: 60%;
    padding-top: 7px;
} */
.header-logo{
    height: 54px !important;
    width: 90% !important;
    padding-top: 7px;
    width: 250px !important;
    /* margin-left: 12%; */
    margin-top: 2%;
    height: 34px !important;
}

li.ant-menu-item.ant-menu-item-only-child a {
    color: #074369;
}
.first-section-header img:first-child {
    width: 35px;
}
.ant-menu-horizontal > .ant-menu-item a:hover {
    color: #074369;
}
button.primary.dd-12:hover {
    background-color: #1f6f99!important;
    color: #fff!important;
}

.loading-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70vh;
  }
/******************Jobdetail index *************/

.job-header {
    background: #f1f3f3;
    padding: 20px 0;
}
.job-header h1 {
    font-size: 30px;
    font-weight: 400;
    margin: 0 130px 11px 0;
}
.job-header .job-attributes li {
    margin-right: 10px;
}
.list-inline>li {
    display: inline-block;
    padding-left: 5px;
    padding-right: 5px;
}
.job-header .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0px;
}
.job-header span.anticon {
    margin-right: 10px;
}

ul.list-inline.job-attributes {
    padding: 0px;
}
.jobds-67 {
    padding: 25px 0 0!important;
}

.job-det  .count span {
    display: inline-flex;
  
}
.job-det .ant-checkbox-wrapper + .ant-checkbox-wrapper{

    margin-left:0px!important;
}
.job-det  label.ant-checkbox-wrapper.ant-checkbox-wrapper-checked {
    display: flex;
}
.cuj-8 {
    padding-left: 16px;
}
.job-info {
    border: 1px solid #cec7c7;
    background-color: #ffff;
    max-width: 100%;
    padding: 30px 30px !important;
    border-radius: 10px;
}
.apply-info {
    border: 1px solid #cec7c7;
    background-color: #ffff;
    max-width: 100%;
    padding: 30px 20px !important;
    border-radius: 10px;
}
.dh-555 {
    margin: 0px!important;
}
.job-info ul {
    padding: 0px 20px;
}
.jo-ti h2 {
    margin-bottom: 37px!important;
    font-weight: 600;
    min-height: 33px;
}
.jo-ti p {
    margin-bottom: 20px;
    min-height: 18px;
}
h2.mb-3 {
    margin-bottom: 37px;
    font-weight: 600;
}

.hideit {
    visibility: hidden;
    position: absolute;
}


.apply-info .ant-checkbox-checked::after {
    border: none!important;
    
}

.job-content-main {
    display: flex;
}
.info-content {
    order: -1;
}
@media (min-width:961px) {
    .info-content {
        order: 1;
    }
}

.mask-phone {
    box-sizing: border-box;
    margin: 0;
    -webkit-font-feature-settings: "tnum";
            font-feature-settings: "tnum";
    font-variant: tabular-nums;
    list-style: none;
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    padding: 4px 11px;
    /* color: rgba(0, 0, 0, 0.85); */
    font-size: 14px;
    line-height: 2;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    transition: all 0.3s;
}

.mask-phone:focus {
    border-color: #d9d9d9 !important;
    box-shadow: none;
    outline: 0;
}

.mask-phone:hover {
    border-color: #1f6f99!important;
    border-right-width: 1px !important;
}

.mask-phone::-webkit-input-placeholder {
    text-overflow: ellipsis;
    color: #c4c4c4;
}

.mask-phone::placeholder {
    text-overflow: ellipsis;
    color: #c4c4c4;
}
.loading-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70vh;
  }
.subscription h2 {
    margin-bottom: 30px;
}
.subs-check {
    margin-bottom: 30px;
}
.button-des {
    background-color: #1f6f99;
    border-color: #1f6f99;
    color:#fff;
    padding: 10px;
    cursor:pointer;
}
.button-des:focus {
    outline: none;
    border: none;
}
.subs-check {
    display: flex;
    flex-flow: column;
}
button.button-des {
    outline: none;
    border: none;
}



.subscription .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0px;
}
/*Header*/
.header {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  max-width: 1200px;
  /* margin: 0 auto; */
  margin: 0px 6.8%;
}

.first-section-header {
  display: flex;
    justify-content: flex-start;
    align-items: center;
    /* max-width: 367px; */
    max-width: 330px;
    cursor: pointer;
    height: 54px !important;
    width: 90% !important;
    padding-top: 0px;
}

.first-section-header input {
  height: 35px;
  width: auto;
  min-width: 240px;
}
.contractjob span.title-h {
  margin-bottom: 0 !important;
}

.contractjob .ant-dropdown-link.ant-dropdown-trigger {
  vertical-align: middle;
  color: #1f6f99}
.post-description p {
  font-size: 12px;
  font-weight: 500;
}
.ratejob span.title-h {
  margin-bottom: 0px !important;
}

.ratejob a.ant-dropdown-link.ant-dropdown-trigger {
  vertical-align: middle;
  color: #1f6f99}
.jobform {
  border: 1px solid #cec7c7;
  background-color: #ffff;
  max-width: 100%;
  padding: 30px 45px !important;
}

.post-heading h2 {
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 20px;
  margin-top: 30px;
}
span.title-h {
  font-weight: 700;
  color: #000;
}
.jobform textarea.ant-input {
  min-height: 90px !important;
}
.c-box {
  /* position: absolute !important; */
  /* bottom: 0 !important; */
  /* padding-left: 9px !important; */
}
/* span.tag-cu {
  padding: 5px 13px !important;
} */
.my-input::before {
  visibility: hidden !important;
}
.Postjob {
  background-color: #EFF2F6;
  box-shadow: 0 0 2px 0px #adadad;
  min-height: calc(100vh - 115px);
  padding-bottom: 7% !important;
}
.ant-menu-horizontal > .ant-menu-item > a:only-child {
  font-weight: 500;
}

.ant-menu-item .anticon {
  display: none;
}

.ant-menu-horizontal > .ant-menu-item {
  border-bottom: 0 !important;
  padding: 0 14px;
}

.ant-menu-horizontal > .ant-menu-item {
  padding-right: 0 !important;
  padding-left: 25px;
}
.ratejob {
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  padding: 6px;
}
.contractjob {
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  padding: 6px;
}
.ratejob span.anticon.anticon-down {
  float: right;
  margin-top: 5px;
  color: #a7a1a1;
}
.contractjob span.anticon.anticon-down {
  float: right;
  margin-top: 5px;
  color: #a7a1a1;
}
.post-description img {
  width: 36px;
  height: 36px;
  border: 2px solid #333;
  padding: 5px;
  margin-right: 10px;
  border-radius: 8px;
}
.ant-input:focus,
.ant-input-focused {
  border-color: #d9d9d9 !important;
  box-shadow: none;
}
p {
  font-size: 16px !important;
  color: #808080;
  margin-bottom: 5px;
}
.img-de {
  display: flex;
  margin-bottom: 10px;
}

.img-de h2 {
  font-weight: 700;
}
ul.items {
  padding-left: 0;
  list-style: none;
  display: flex;
  margin-bottom: 10;
}
.space .title-h {
  display: inline-block;
  vertical-align: middle;
}
ul.items li {
  padding-right: 20px;
  position: relative;
  font-size: 13px;
  color: #ababab;
  font-weight: 600px;
}

ul.items li:after {
  position: absolute;
  content: ">";
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  right: 7px;
  font-weight: 700;
}

ul.items li:last-child:after {
  display: none;
}
.ant-menu-horizontal {
  border-bottom: 0 !important;
}
.ant-menu-horizontal > .ant-menu-item > a {
  color: #1f6f99}
.post-description img {
  /* width: 45px; */
  /* height: auto; */
  width: 34px;
  height: 36px;
  border: 2px solid #333;
  padding: 5px;
  margin-right: 10px;
  border-radius: 8px;
}
.space {
  margin-bottom: 15px;
  position: relative;
}
span.tag-cu {
  font-size: 12px;
  color: #074369!important;
  border: 1px solid #074369!important;
  background-color: #fff!important;
  border-radius: 20px;
  padding: 3px 12px;
  margin-right: 10px;
  font-weight: 600;
}
.ant-input-affix-wrapper:hover {
  border-color:#1f6f99;
  border-right-width: 1px !important;
}
.ant-btn-link {
  color: #1f6f99!important;
 
}
.ftext {
  text-align: center;
  padding: 17px;
  box-shadow: 0 0 1px 0px #adadad;
  color: #adadad;
  font-size: 13px;
}
.submitbtn {
  margin-top: 20px;
  background-color: #1f6f99 !important;
}
.my-input input {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.pointer {
  cursor: pointer;
}
.c-box.fss-12 {
  flex-wrap: wrap;
}

.fss-12 span.tag-cu {
  white-space: nowrap;
}
h4 {
  font-weight: 700;
  color: #000;
  
}
.first-section-header img:first-child {
  position: relative;
  /* top: -4px; */
  top: -8px;
  margin-right: 2px;
}
.activepagination:hover {
  color: #06b9b9;
}
.activepagination {
  color: #078d9a;
}
.ant-input-affix-wrapper{
    position: relative;
    width: 85%;
    min-width: 0;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    line-height: 1.5715;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    display: flex;
    margin: 6% auto 0 !important;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

@media only screen and (max-width: 768px){
  .ant-input-affix-wrapper{
    position: relative;
    width: 81% !important;
    min-width: 0;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    line-height: 1.5715;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    display: flex;
    margin: 2% auto 0 !important;
    justify-content: center;
    align-items: center;
    margin-left: 7% !important;
  }
}
@media (min-width: 768px) {
  .mobile {
    display: none;
  }
  span.locate {
    display: flex;
  }

  span.locate p {
    margin-right: 10px;
  }
}

@media (max-width: 767px) {
  .mobilev.hide {
    display: none;
  }
  .desktop {
    display: none;
  }
  .candidate-form {
   
    padding: 30px 15px !important;
}
.ant-checkbox + span {
   padding-right: 0px; 
  padding-left: 8px;
  font-size: 13px;
}
  .header .ant-menu-horizontal {
    display: flex;
    flex-direction: column;
  }
  .header {
    /* padding-left: 16px; */
    /* padding-right: 16px; */
  }

  .first-section-header {
    min-width: 160px !important;
  }
  .ant-menu-horizontal .ant-menu-submenu:first-child {
    border-bottom: 0;
  }
  .deu-3 {
    padding: 0 16px;
  }
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover{
    color: transparent!important;
    border-bottom: none!important;
  }
  .ant-menu-horizontal
    .ant-menu-submenu:first-child
    .ant-menu-submenu-title
    span {
    color: #ffffff;
  }

  .ant-menu-horizontal .ant-menu-submenu:first-child .ant-menu-submenu-title {
    padding: 0;
    width: 27px;
    text-align: center;
  }

  .ant-menu-horizontal
    .ant-menu-submenu:first-child
    .ant-menu-submenu-title
    span:before {
    content: "";
    position: absolute;
    top: 12px;
    height: 3px;
    left: 0;
    width: 100%;
    background: #074369;
  }
  .ant-menu-horizontal
    .ant-menu-submenu:first-child
    .ant-menu-submenu-title
    span:after {
    content: "";
    position: absolute;
    bottom: 12px;
    height: 3px;
    width: 100%;
    left: 0;
    background: #074369;
  }

  .ant-menu-horizontal
    .ant-menu-submenu:first-child
    .ant-menu-submenu-title:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 3px;
    background: #074369;
    top: 50%;
    left: 0;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
  }

  /* homepage */

  .cus-33 .container {
    padding: 50px 16px;
  }
  .heading-text span {
    font-size: 22px;
  }
  .heading-text h2 {
    font-size: 22px;
  }
  button.ant-btn.post-23 {
    height: 38px;
    padding: 0px 20px;
  }

  /* viewjob */
  .ant-row.dh41 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .ant-row.dh41 .ant-col.ant-col-xs-24 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .ant-row.dh41 .ant-col.ant-col-xs-24 .jobform {
    padding: 16px 16px !important;
  }
  .view-link {
    display: flex;
    flex-wrap: wrap;
    padding-top: 16px;
  }

  .view-link button {
    width: 50%;
    margin-bottom: 7px;
    text-align: left;
  }

  .heading-text {
    flex-direction: column;
    align-items: center;
  }

  .butn-4 {
    justify-content: center;
  }

  /* Postjob */
  .ratejob {
    margin-bottom: 26px;
  }
  .post-description {
    display: none;
  }

  .jobform button.ant-btn {
    margin-bottom: 10px;
    margin-right: 0px !important;
  }

  .butn-4 {
    display: flex;
    flex-direction: column;
  }

  /* footer */

  .ftext {
    font-size: 11px;
  }

  /* Search Page */
  .ant-row.ant-form-item.chlist {
    margin-top: 20px;
  }
  .active-45 .ant-select-show-search {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0px;
    width: auto !important;
  }
  .search-121 {
    padding: 0px 16px;
  }
  .sert {
    margin-top: 1rem;
  }
  .content-4 {
    margin-top: 10px;
  }
  .search-121 {
    padding: 0px 16px;
  }

  .search-box {
    justify-content: flex-start;
  }

  .filt-btn {
    margin-right: 12px;
  }

  .visi-67 .ant-slider {
    max-width: calc(100% - 20px);
    margin: 0 auto;
  }
  .search-box {
    margin-top: 0;
  }

  .search-box h4 {
    display: none;
  }
  .active-45 .ant-select.ant-select-single.ant-select-show-arrow {
    margin-bottom: 20px;
  }

  .content-4 > span.det-4.mobile {
    width: 50%;
    text-align: right;
  }
  .search-box .search-input {
    width: 100%;
    margin-bottom: 15px;
  }

  .content-4 {
    flex-wrap: wrap;
  }
  span.locate {
    flex-wrap: wrap;
  }

  span.locate p {
    width: 100%;
  }
  span.locate {
    flex-wrap: wrap;
  }

  span.locate p {
    width: 100%;
  }

  span.locate p {
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 10px;
  }
  .content-4 > p:first-child {
    width: 50%;
    margin-right: 0;
  }
  span.det-4 {
    font-size: 12px;
  }

  span.locate {
    display: flex;
    white-space: nowrap;
    width: 50%;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .displaybtn {
    display: flex;
  }
  .guest-34 {
    margin-bottom: 30px;
  }
  .page-33.fitl-5 {
    padding: 20px;
  }
  .detail-66 p {
    margin-bottom: 20px;
  }
  .guest-34 {
    padding: 10px 11px !important;
  }
  .candidate-22.mobile svg {
    color: #c5c3c3 !important;
    font-size: 12px;
    font-weight: 800;
  }
  .candidate-profile {
    padding-bottom: 0;
  }
  .joining {
    text-align: left!important;
    margin-top: 20px;
}

.cl-btn {
  justify-content: space-between;
}

span.per-23 {
  
  font-size: 12px!important;
}

.design-sp span {
  
  white-space: unset!important;
 
}
.search-item h4 {
 
  white-space: nowrap;
}
.cl-btn button {
  padding: 5px;
}
  /* viewcandidate Page */

  .des-34 {
    padding: 0px 16px;
  }
  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0px;
  }
  .profile-skill {
    flex-wrap: wrap;
  }
  .ant-row.dus-45 {
    margin-left: -16px;
    margin-right: -42px;
  }

  .ant-row.dus-45 .ant-col {
    padding: 0 !important;
  }
  .gud-34 {
    margin: 0 !important;
  }
  .ant-row.cuts-12 {
    margin: 0 !important;
  }
  .pts-5 .ant-card-body {
    padding: 24px 10px;
  }
  span.per-23 {
    font-weight: 500;
    color: #000;
    white-space: nowrap;
    font-size: 10px;
  }
  button.primary.dd-12 {
    background-color: #fff;
    border: none;
    outline: none;
    padding: 0;
    color: #1f6f99;
    min-height: auto!important;
    line-height: initial;
    border-radius: unset;
}  
.coin-img img {
  width: 90px;
  height: 90px;
}
.img-second img {
 
  display: none;
}
.std-1 h3 {
 
  font-size: 17px!important;
  
}

.switch h4 {
 
  font-size: 23px;
  
}
.img-second {
 
  margin-bottom: 0px!important;
}
.dd-4 {
  text-align: center;
}

.dd-4 div {
  max-width: 250px;
  margin: 0px auto;
  text-align: left;
}
ul.list-yu li {
  font-size: 12px;
  font-weight: 100;
  margin-top: 3px;
}
.fo-text span {
  color: #999999;
  white-space: nowrap;
  font-size: 11px;
}
.footer-2 {
 
  padding: 5px;
}
.std-1 h3 {
 
  margin-top: 59px!important;
}
ul.list-yu {
  padding: 0;
}
.switch p {
  font-size: 15px!important;
 
}
.std-1 h3 {
  margin-bottom: 50px;
  margin-top: 26px!important;
}


.it-card {
 
  margin: 0 auto!important;
}
.opening-text {
  padding: 0px!important;
}
.locat-45 h4 {
  font-size: 25px!important;
  
  padding-left: 0px!important;

}
h3.mobile.small-re {
  position: relative;
}
.gus-12 img {
  position: unset!important;
  left: 0!important;
  -webkit-transform: unset!important;
          transform: unset!important;
  width: 100%!important;
}
.main-heading h3 {
  
  font-size: 20px;
 
}
.cg {
  
  padding: 20px!important;
}
h3.mobile.small-re:before {
  content: "";
  width: 171px;
  position: absolute;
  height: 2px;
  left: 38%;
  top: 100%;
  background-color: #000;
}
.dyf {
  padding-left: 15px!important;
  padding-right: 15px!important;
}
.cvn-2 {
 
  margin-bottom: 30px;
}
span.des-rt {
 
  padding: 10px 5px 10px 20px;
  margin-right: 0px;
}
.features {
  padding: 30px 40px;
}

button.ant-btn.scus-2 {
  padding: 2px 16px;
  border-width: 1px;
  margin: 0px 7px 10px;
}

button.ant-btn.scus-2 span {
  font-size: 12px!important;
}
button.ant-btn.scus-filled {
  padding: 7px 30px;
}

.gtt {
  padding: 0!important;
  width: 100%;
}

button.ant-btn.scus-filled span {
  font-size: 14px!important;
}
.dd-4 {
  padding-left: 6%;
}
.home-page {

  background-position: 112px 6%;
  
  background-size: 200%;
  
}
.locat-45 h6 {
  font-size: 17px!important;
}
.gtt {
  text-align: center!important;
}
.std-1 h3 {
  font-size: 19px;
  text-align: center;
}
.coin-img img {
  margin-top: 30px;
}
.switch-button button {
 
  padding: 3px 30px;
  
}
.locat-45 p {
  font-size: 15px!important;
}
.it-card p {
  font-size: 15px!important;
 
}
.it-card button {
 
  padding: 4px 40px!important;
}
.home-page {
  max-width: unset!important;
 
  background-image: url("/static/media/Group 41_mob.8ff4fecb.png");
  background-position: right 80px;
  background-repeat: no-repeat;
  background-size: 50%;
  box-shadow: 0 0 2px 0px #adadad;
}
.opening-text {
    padding: 0px!important;
    margin-bottom: 50px;
}
button.ant-btn.homesign.ant-btn-primary {
  border: unset;
  border-radius: unset;
  box-shadow: none;
  padding: 0!important;
}
button.ant-btn.homesign.ant-btn-primary span {
  font-weight: 400;
}


/****************** candidate profile ****************/

.contract-12.Postjob {
  padding: 0px 16px!important;
}




/****************** jobddescription modal****************/

.job-d .ant-modal-wrap, .ant-image-preview-wrap {
  
  right: 42%;
 
}







/****************** jobdetail****************/


h2.mb-3 {
  margin-bottom: 20px;
  
}

/****************** header****************/
button.primary.dd-12 {
  padding: 0px!important;
  font-weight: 400;
}

/****************** Joblist popconfirm****************/

.ant-popover-content {
  margin: 0px 10px;
}










}

@media (max-width: 340px) {
  .candi-head {
    font-size: 12px;
  }
  .gud-34 {
    margin: 0 !important;
  }
  .ant-row.cuts-12 {
    margin: 0 !important;
  }
}

.sert {
  margin-top: 1rem;
}
.profile-skill {
  flex-wrap: wrap;
}

.detail-66 p {
  margin-bottom: 20px;
}
@media (max-width: 374px) {

  .switch p {
    font-size: 13px!important;
}
.switch h4 {
  font-size: 19px;
}
ul.list-yu li {
  font-size: 11px;
  font-weight: 100;
  margin-top: 5px;
}

}

.ant-input{
    box-sizing: border-box;
    margin: 0;
    -webkit-font-feature-settings: "tnum";
            font-feature-settings: "tnum";
    font-variant: tabular-nums;
    list-style: none;
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    line-height: 2;
    background-color: #fff;
    background-image: none;
    /* border: 1px solid #fff; */
    border-radius: 4px;
    transition: all 0.3s;
}

.posted-time{
font-style: normal;
font-weight: normal;
font-size: 12px;
line-height: 16px;
color: #666666;
margin-top: 10%;
display: inline-block;
}

.filter-img{
  display: none;
  background-color: #ffffff;
  padding: 6px;
  box-shadow: 0px 0px 1px 1px rgb(0 0 0 / 10%);
  border-radius: 4px;
  cursor: pointer;
}

.btn-read-more{
  color: #074369;
  font-weight: bold;
  border-bottom: 1px solid #094369; 
  cursor: pointer;
  display: inline;
  /* position: absolute;
  bottom: 5%; 
  right: 1%; */
}

@media only screen and (max-width: 768px){
  .posted-time{
    margin-top: 0;
    display: block;
  }
  .posted-time--desktop{
    display: none;
  }
  .filter-img{
    display: block;
    margin-top: 0;
  }
}
