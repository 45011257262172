body {
    margin: 0;
    padding: 0;
  }
  /************************** Navbar **************************/
  
  .navbar {
    padding: 0.625px 0 .9375rem 0;
    margin-bottom: 2%;
  }
  .navbar img {
    /* width: 13.75rem;
    height: 2.216875rem; */
     margin-left: 34%;
    margin-top: 1%;
  }
  
  .navbar-item {
    /* font-size: 1.125rem; */
    font-size: 14px;
    font-weight: 500;
    line-height: 1.125rem;
    display: inline-block;
    padding-top: 1.875rem;
    color: #074369;
    cursor: pointer;
  }
  @media (min-width: 1200px){
    .navbar .ant-col-xl-3 {
        display: block;
        flex: 0 0 12.5%;
        max-width: 8.5%;
    }
  }
  
  .navbar-item--active {
    font-size: 1.125rem;
    line-height: 1.125rem;
    display: inline-block;
    margin-top: 25px;
    color: #074369;
    cursor: pointer;
    border: 3px solid #074369;
    padding: 4% 15%;
    border-radius: 6px;
  }
  
  .navbar-list {
    font-size: 1rem;
    line-height: 1.1875rem;
    color: #062d45;
    padding: 5%;
    cursor: pointer;
  }
  .navbar-list li {
    padding: 2% 0;
  }
  .ant-drawer-body {
    padding: 0 !important;
    height: 50vh !important;
    width: 50vw !important;
  }
  .ant-drawer-body li {
    display: block;
  }
  
  .card-body {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    position: absolute;
    top: 30%;
    left: 14%;
  }
  @media only screen and (max-width: 992px) {
    .listItem {
      display: none;
    }
    .navbar-btn {
      display: inline !important;
      background-color: white;
      border: 0;
      padding: 0 0;
      position: absolute;
      top: 20px;
      right: -1.25rem;
      cursor: pointer;
      outline: none;
    }
  }
  @media only screen and (min-width: 992px) {
    .navbar-btn {
      display: none !important;
    }
  }
  @media only screen and (max-width: 600px) {
  .navbar{
    padding-top: 0 !important;
  }
  .navbar img{
    width: 12.375rem !important;
    height: 2.216875rem !important;
    margin-left: 8% !important;
    /* margin-top: 9% !important; */
  }
  .navbar-item--active {
    font-size: .818181rem;
    line-height: 1rem;
  }
  
  }
  
  @media only screen and (max-width: 767px) {
    .card-body {
      top: 48%;
      left: 64%;
      width: 36%;
      /* top: 108px;
    left: 207px; */
      /* width: 200px; */
    }
  }
  /************************** Navbar End **************************/
  
  /************************** Header **************************/
  
  @media only screen and (max-width: 600px) {
    .header-home {
      text-align: center;
    }
  }
  
  
  .header-home {
    padding: 6% 0;
    padding-bottom: 0;
    font-style: normal;
    margin: 2% 7% 10%;
    margin-right: 0%;
  }
  
  .header-content {
    margin: 1.25rem;
    margin-right: 12%;
  }
  
  .header-heading {
    font-weight: bold;
    font-size: 2.5rem;
    line-height: 3.0625rem;
    color: #074369;
    margin-bottom: 10%;
    padding-right: 16%
  }
  .header-para {
    font-weight: normal;
    font-size: 1.5rem;
    line-height: 2.125rem;
    color: #666666;
    margin-top: 7%;
    margin-bottom: 5%;
    padding: 2% 23% 2% 0;
  }
  .header-image {
    width: 90% !important;
    height: auto !important;
  }
  
  .header-btn{
    font-size: 1rem;
    line-height: 1.1875rem;
    position: relative;
    right: 3px;
    width: 12.3rem;
    height: 3.6rem;
    margin-bottom: 15%;
    background: #074369;
    border: 2.6px solid #ffffff;
    box-sizing: border-box;
    border-radius: 0.5rem;
    cursor: pointer;
    font-style: normal;
    font-weight: bold;
    text-align: center;
    color: #ffffff;
  }
  
  @media only screen and (max-width: 600px) {
    .header-home {
      text-align: center;
    }
    #header-desktop--view {
      display: none;
    }
    .header-btn{
      width: 60% !important;
      height: 3.0rem !important;

    }
    .btn {
      width: 54% !important;
    }
    .header-image {
    }
    .header-heading {
      font-size: 1.5rem;
      line-height: 1.8125rem;
      padding: 0%;
      margin: 2px;
    }
    .header-para {
      font-size: 14px;
      line-height: 22px;
      padding: 0%;
    }
  }
  @media only screen and (min-width: 600px) {
    #header-mobile--view {
      display: none;
    }
  }
  
  /************************** Header End **************************/
  
  /************************** Features **************************/
  .features-heading {
    font-style: normal;
    font-weight: bold;
    font-size: 2.5rem;
    line-height: 3.0625rem;
    color: #074369;
    text-align: center;
    margin-top: 5%;
  }
  .features-para {
    font-style: normal;
    font-weight: normal;
    font-size: 1.5rem;
    line-height: 2.125rem;
    text-align: center;
    color: #666666;
    padding: 0 22%;
  }
  .features-para--mobile{
    display: none;
  }
  @media only screen and (max-width: 600px) {
    .features-heading,
    .features-para {
      text-align: left;
      padding: 0;
      padding-left: 4%;
    }
    .features-col {
      padding: 2% 0% !important;
    }
    .features-heading {
      color: #062d45;
      font-size: 1.5rem;
      line-height: 1.8125rem;
      padding-top: 15%;
    }
    .features-para {
      font-size: .875rem;
      line-height: 1.375rem;
      padding-right: 6%;
    }
    .features-content {
      padding-right: 5%;
    }
    .feature-block--heading{
      font-size: 1.25rem !important;
      line-height: 1.5rem !important;
      
    }
    .features-para--mobile{
      display: block;
    }
    .features-para--desktop{
      display: none;
    }
  }
  .feature-img {
    height: 1.875rem;
    width: 1.875rem;
    margin-bottom: 4%;
  }
  .feature-block--heading {
    font-size: 1.5rem;
    line-height: 1.625rem;
    font-style: normal;
    font-weight: normal;
    margin-bottom: 0;
    border-bottom: 1px solid #9f9f9f;
    color: #2e2e2e !important;
    display: inline-block;
    padding-bottom: 3%;
    word-spacing: 30000px;
  }
  .feature-block--para {
    margin-top: 10%;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #2e2e2e !important;
    padding-right: 6%;
  }
  .features-col {
    padding: 2% 5%;
  }
  .container {
    padding: 2% 5%;
  }
  /************************** Features End **************************/
  
  /************************** IT section **************************/
  .it {
    padding: 6% 0;
    padding-bottom: 0;
    font-style: normal;
    margin: 8% 7%;
  }
  
  .it-content {
    margin: 2.25rem;
    margin-right: 12%;
  }
  
  .it-heading {
    font-weight: bold;
    font-size: 2.5rem;
    line-height: 3.0625rem;
    color: #074369;
    margin-bottom: 10%;
    padding-right: 14%;
  }
  .it-para {
    font-weight: normal;
    font-size: 1.5rem;
    line-height: 2.125rem;
    color: #666666;
    margin-top: 7%;
    margin-bottom: 5%;
    padding: 2% 2% 2% 0;
  }
  @media only screen and (max-width: 752px){
    .it-image {
      width: 60% !important;
      height: auto !important;
    }
  }
  /* .div{
    padding-left: 25%;
  } */
  .it-image {
      width: 80%;
      height: auto;
  }
  
  @media only screen and (max-width: 1320px) {
    .div{
      padding-left: 0% !important;
      text-align: center !important;
    }
   
    .div-woman{
      padding-left: 5% !important;
      margin: auto;
    }
    .card-body{
      position: absolute;
      right: 25% !important;
      bottom: 5% !important;
      /* left: -44px; */
      left: -1rem;
    }
  }
  @media only screen and( max-width: 768 px){
    .it-image {
      width: 60% !important;
      height: auto !important;
    }
  }
  
  .div-woman{
    padding-left: 25%;
  }
  
  @media only screen and (max-width: 600px) {
    .it {
      text-align: center;
    }
   
    .div-woman{
      padding: 0 !important;
      text-align: center !important;
      margin: auto !important;
    }
    .div {
      padding-left: 1%;
      text-align: center !important;
    }
    .it-desktop--view {
      display: none;
    }
    .btn {
      font-size: 1rem !important;
      line-height: 1.1875rem !important;
    }
    .it-image {
      height: 270px !important;
      width: 257px !important;
    }
    .it-mobile--view {
      margin: 10% 0;
      width: 70% !important;
    }
    .it-heading {
      font-weight: bold;
      font-size: 1.375rem !important;
      line-height: 1.8125rem !important;
      color: #074369;
      margin-bottom: 10%;
      padding-right: 0%;
      }
    
    .it-para {
      font-size: .875rem;
      line-height: 1.5rem;
      text-align: center;
    }
  }
  @media only screen and (min-width: 600px) {
    .it-mobile--view {
      display: none;
    }
  }
  /************************** IT End **************************/
  
  /************************** ReasonSection **************************/
  .reason-section {
    background-color: #074369;
  }
  
  .reason-section-content {
    padding: 3% 5% 5% 0;
    color: #ffffff;
  }
  .reason-section-heading {
    font-style: normal;
    font-weight: bold;
    font-size: 2.3rem;
    line-height: 3.0625rem;
    letter-spacing: 1px;
    color: #ffffff;
    margin-bottom: 8%;
  }
  .reason-section-heading--mobile-view{
    display: none;
  }
  
  .tick-img {
    height: 5%;
    width: 5%;
    margin-top: -1%;
  }
  dl {
    font-style: normal;
    font-weight: normal;
  }
  dt {
    display: inline;
    font-weight: bold;
    font-size: 1.3rem;
    line-height: 1.8125rem;
    padding-left: 1.8%;
  }
  dd {
    font-size: 1.25rem;
    line-height: 1.75rem;
    /* padding: 1% 10% 5% 7%; */
    padding: 1% 5% 5% 7%;
  }
  .reason-section-btn {
    font-style: normal;
    font-weight: bold;
    font-size: 1.25rem;
    line-height: 1.5rem;
    text-align: center;
    color: #ffffff;
    background: #074369;
    border: 2.8px solid #ffffff;
    box-sizing: border-box;
    border-radius: 3px;
    cursor: pointer;
    padding: 2% 6%;
  }
  .reason-section-btn--mobile{
    display: none;   
  }
  @media only screen and (max-width: 600px) {
    .reason-section-images {
      height: 100vh;
    }
    .reason-section-heading {
      text-align: center;
      font-size: 1.5rem;
      line-height: 1.8125rem;
    }
    dt {
      font-size: 1.25rem;
      line-height: 1.5rem;
      padding-left: 4%;
    }
    dd {
      font-style: normal;
      font-weight: normal;
      font-size: 1.125rem;
      line-height: 1.5rem;
      padding-left: 13%;
    }
    .reason-block{
      margin: 5% 0;
    }
    .reason-section-content {
      padding: 5%;
    }
    .head {
      display: none;
    }
    .tick-img {
      height: 1.75rem;
      width: 1.75rem;
      margin-top: -1%;
    }
    .reason-section-heading--mobile-view{
      display: block;
      padding-top: 20%;
      margin-bottom: -17%;
    }
    .reason-section-heading--desktop-view{
      display: none;
    }
    .reason-section-btn--mobile{
     display: block;
     font-size: 1rem;
     line-height: 1.8125rem;
     margin: auto;
     border: 2px solid #ffffff;
     width: 60%;
     margin-bottom: 15%;
    }
    .reason-section-btn--desktop{
      display: none;
    }
    .grid-image{
      width: 100%;
      height: auto;
    }
    @media only screen and (max-width: 600px) {
      .grid-image {
        width: 80%;
      }
    }
  }
  /************************** ReasonSection End **************************/
  
  /************************** Review Section **************************/
  .reviews {
    padding: 8%;
  }
  .reviews-heading {
    text-align: center;
    font-weight: bold;
    font-size: 2.5rem;
    line-height: 3.0625rem;
    color: #074369;
    margin-bottom: 3%;
  }
  .card {
    box-shadow: 0px 0px 19px 2px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 4%;
    margin: 4%;
  }
  .card-title {
    font-size: 1.5rem;
    line-height: 1.5rem;
    margin-top: 3%;
  }
  .card-subtitle {
    font-size: 1rem;
    line-height: 1.625rem;
  }
  .card-company {
    font-size: 1rem;
    line-height: 1.625rem;
    position: absolute;
    top: 9.8rem;
  }
  .card-text {
    font-size: 1rem;
    line-height: 1.625rem;
    color: #2e2e2e;
  }
  .stars img {
    padding: 10% 3% 5% 0;
  }
  
  .center-image {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  @media only screen and (max-width: 600px) {
    .reviews {
      display: none;
    }
  }
  
  
  /************************** Review section end **************************/
  
  /************************** Map Section **************************/
  
  .map {
    padding: 6% 0;
    padding-bottom: 0;
    font-style: normal;
    margin: 0 7%;
    margin-bottom: 4%;
  }
  
  .map-content {
    margin: 1.25rem;
    margin-right: 12%;
  }
  
  .map-heading {
    font-weight: bold;
    font-size: 2.5rem;
    line-height: 3.0625rem;
    color: #074369;
    margin-bottom: 10%;
  }
  .map-para {
    font-weight: normal;
    font-size: 1.5rem;
    line-height: 2.125rem;
    color: #666666;
    margin-top: 7%;
    margin-bottom: 5%;
  }
  .div {
    padding-left: 25%;
  }
  .map-image {
    height: auto!important;
    width: 100% !important;
  }
  
  .btn {
    font-size: 1.25rem;
    line-height: 1.5rem;
    position: relative;
    right: 3px;
    width: 15rem;
    height: 3.5rem;
    margin-bottom: 15%;
    background: #074369;
    border: 2.6px solid #ffffff;
    box-sizing: border-box;
    border-radius: 6px;
    cursor: pointer;
    font-style: normal;
    font-weight: bold;
    text-align: center;
    color: #ffffff;
  }
  
  .map-btn--mobile{
      font-size: 1rem;
      line-height: 1.1875rem;
      position: relative;
      right: 3px;
      width: 15rem;
      height: 3.5rem;
      margin-bottom: 15%;
      background: #074369;
      border: 2.6px solid #ffffff;
      box-sizing: border-box;
      border-radius: 6px;
      cursor: pointer;
      font-style: normal;
      font-weight: bold;
      text-align: center;
      color: #ffffff;
  }
  
  @media only screen and (max-width: 600px) {
    .map {
      text-align: center;
      margin-top: 11%; 
      margin-bottom: 11%; 
    }
    .div {
      padding-left: 0%;
    }
    #map-desktop--view {
      display: none;
    }
    .map-image {
        width: 100% !important;
        height: auto !important;
    }
    .map-heading {
      font-size: 1.375rem;
      line-height: 1.8125rem;
      margin-top: 13%;
    }
    .map-para {
      font-size: 1rem;
      line-height: 1.625rem;
    }
    .map-btn--mobile{
      width: 60% !important;
    }
  }
  @media only screen and (min-width: 600px) {
    #map-mobile--view {
      display: none;
    }
  }
  
  /************************** Map section end **************************/
  
  /************************** Footer **************************/
  
  .footer {
    text-align: center;
    /* font-family: Proxima Nova; */
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.625rem;
    padding: 2% 0 1% 0;
  }
  .footer-img {
    width: 14.9375rem;
    height: 2.4668rem;
  }
  .footer-text {
    padding-top: 2%;
    color: #333333;
  }
  .footer-word {
    color: #074369;
  }
  
  /* footer for mobile screen */
  @media only screen and (max-width: 768px){
    .footer-word{
      margin-top: -1%;
      margin-bottom: 5%
    }
    .footer{
      padding-top: 8%;
    }
  }
  /************************** Footer End **************************/
  