.candidate-form {
    border: 1px solid #cec7c7;
    background-color: #ffff;
    max-width: 100%;
    padding: 30px 45px !important;
}
.title-name {
    font-weight: 600;
    color: #000;
}
.wrk-45 {
    border: 1px solid #d9d9d9;
    padding: 6px 11px;
}
.w-12 span {
    display: flex;
}
.prefer {
    display: flex;
}
.will {
    margin-top: 10px;
}
.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #1f6f99;
    border-color: #1f6f99;
}
.count span {
    display: flex;
}
.contractor{
    padding-top: 3%;
}
.contractor h2 {
    margin-bottom: 20px;
}
.set-space {
    margin-bottom: 15px;
}
.pace.count svg {
    margin-top: 5px;
}
.d74e {
    display: flex;
    justify-content: space-between;
    padding: 3px 10px;
    border: 1px solid #d9d9d9;
}

.d74e label.d5-name {
    background:#1f6f99;
    color: #ffffff;
    border-radius: 2px;
    padding: 3px 10px;
}
.wrk-45 a.ant-dropdown-link.ant-dropdown-trigger {
    width: 100%;
    display: block;
    color: #bfbfbf;
   
}

.wrk-45 a.ant-dropdown-link.ant-dropdown-trigger span.anticon.anticon-down {
    position: absolute;
    right: 10px;
    top: 70%;
}

.cons-desired a.ant-dropdown-link.ant-dropdown-trigger {
    width: 100%;
    display: block;
    color: #bfbfbf;
    
}
.cons-desired a.ant-dropdown-link.ant-dropdown-trigger span.anticon.anticon-down{

    position: absolute;
    right: 10px;
    top: 35%;
}
.pace {
    margin-bottom: 20px;
}
.w-12.pace {
    position: relative;
}

.em-23 span {
    display: flex;
}
.resume-33.pace {
    display: flex;
    flex-direction: column;
}
.sign-in {
    display: flex;
    justify-content: center;
    padding-top: 50px;
  }
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
   
    border-bottom:none!important;
}
button.ant-btn.concan-btn.ant-btn-primary {
    background-color: #074369;
    border-color: #074369;
    padding: 4px 35px;
    height: 38px;
    border-radius: 4px;
}

.dh41 .count span {
    display: inline-block!important;
}
.contract-12 span.tag-cu {
    white-space: nowrap;
    margin-bottom: 5px;
}
.contract-12 .c-box {
   
    flex-wrap: wrap;
}
/* .pace.count {
    display: flex;
    flex-direction: column;
} */
/* 
.resume-33.pace inputlabel {
    margin-right: 5px;
} */

/* input[type="file"] {
    width: 45%;
} */
.color-dark-blue{
    color: #074369;
}