.list-col-ui .heading {
  margin-bottom: 15px;
  display: block;
  font-weight: 600;
}

.list-col-ui .input-group-prepend .input-group-text {
  padding: 0;
  margin-right: 10px;
  position: relative;
}
.list-col-ui .input-group-prepend .input-group-text input[type="checkbox"] {
  -webkit-appearance: none;
  width: 15px;
  height: 15px;
  border: 1px solid gray;
}

.list-col-ui
  .input-group-prepend
  .input-group-text
  input[type="checkbox"]:checked:after {
  opacity: 1;
}

.list-col-ui
  .input-group-prepend
  .input-group-text
  input[type="checkbox"]:after {
  position: absolute;
  content: "\2713";
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
}

.list-col-ui .input-group-prepend .input-group-text {
  border: none;
  background-color: transparent;
}

.list-col-ui label {
  margin-bottom: 0px;
}

.search-ico svg {
  width: 22px;
  stroke: #ffffff;
  fill: #ffffff;
}
button.search-ico.btn.btn-primary {
  border-radius: 0px 4px 4px 0px;
  padding: 5px 10px;
}

.cu-filter {
  margin-bottom: 1.25rem;
  border: 1px solid #00000021;
  border-radius: 2px;
}
.sert {
  margin-top: -31px;

  flex-wrap: wrap;
}
.ant-slider {
  padding: 0;
  margin: 0;
}
.ant-slider:hover .ant-slider-rail {
  background-color: #074369;
}


.ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused {
  border-color: #074369;
}
.ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
  border-color: #074369;
  color: #074369;
}
.ant-slider-track:hover{
  color: #074369;
}
.ant-slider-handle{
  background-color: #074369 !important;
}
.ant-slider-handle-dragging{
  color: #074369;
}
.ant-slider-rail {
  background-color:#666666 !important;
}
.ant-slider .ant-slider-track {
  background-color: #074369;
}
.ant-slider-handle-dragging.ant-slider-handle-dragging.ant-slider-handle-dragging{
  box-shadow: 0 0 0 5px rgba(7, 67, 105, 0.3);
}

/* .ant-slider-handle:focus{
  border-color: #074369;
} */
.tag-cu .anticon-close-circle {
  position: absolute;
  top: -8px;
  right: -8px;
}

ul.list {
  margin: 0;
  background: #ffffff;
  list-style-type: none;
  margin-top: -11px;
  position: relative;
  width: 100%;
  z-index: 99;
  height: 115px;
  overflow-x: auto;
  border: 1px solid #d8d8d8;
  padding: 5px 20px;
}

ul.list li {
  padding: 6px 0px;
  font-size: 15px;
  border-top: 1px solid #d8d8d8;
  margin-top: -1px;
  font-weight: 700;
  color: #222;
  cursor: pointer;
}

ul.list li:first-child {
  border-top: none;
}

.c-box {
  display: flex;
  z-index: 99;
  width: 100%;
  margin: 10px 0px;
}

.my-input {
  position: relative;
}

.list-type {
  margin-top: -10px;
  position: relative;
  width: 100%;
  z-index: 99;
  height: 183px;
  overflow-x: auto;
  display: block;
  background: #efefef;
}

.list-type span {
  display: block;
  padding: 10px 20px;
  border-top: 1px solid #d8d8d8;
  margin-top: -1px;
  font-size: 16px;
}

.my-input::before {
  content: "";
  background: url(../../../../assets/search.png);
  width: 20px;
  height: 20px;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 999;
  position: absolute;
  display: block;
  top: 6px;
  left: 10px;
}

.my-input .close {
  content: "";
  background: url(../../../../assets/close.png);
  width: 20px;
  height: 20px;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 999;
  position: absolute;
  display: block;
  top: 6px;
  right: 10px;
  cursor: pointer;
}

.my-input input {
  padding-left: 40px;
  padding-right: 40px;
}

.ant-checkbox-wrapper {
  margin-bottom: 10px;
}
.work-auth {
  margin-top: 21px;
}
.work-auth inputlabel {
  margin-right: 2px;
}
.skil-34 span {
  border: 1px solid #1f6f99;
  border-radius: 20px;
  padding: 2px 3px;
  margin-right: 7px;
  color: #fff;
  background-color: #1f6f99;
}

.search-box button.ant-btn {
  background-color: #1f6f99;
  color: #fff;
  border-color:  #1f6f99;
}
.ant-input:hover {
  border-color:  #1f6f99!important;
  border-right-width: 1px !important;
}

.gud-34 {
  /* border-top: 1px solid rgb(217, 217, 217); */
  width:100%;
}
label.chck.ant-checkbox-wrapper {
  color: #a5a3a7;
}
span.heading {
  margin-bottom: 9px !important;
}
.df-4 {
  margin-top: 8px;
  margin-bottom: 0.5rem;
}
span.anticon.anticon-plus-circle {
  font-size: 20px;
}
a.ant-dropdown-link.adt-23.ant-dropdown-trigger svg {
  /* margin-left: 135px; */
  /* position: absolute;
  top: 5px;
  right: 10px; */
}

button.ant-btn.skill span.anticon.anticon-plus-circle {
  position: relative;
  left: 5px;
}

button.ant-btn.skill {
  padding: 5px 10px;
}
.gud-34 label.ant-checkbox-wrapper {
  margin-bottom: 0;
}
.range-33 {
  display: flex;
  justify-content: space-between;
  color: #666666;
}
.work-auth .ant-col.ant-col-24 {
  margin-bottom: 0px !important;
}
.filters-result {
  margin-top: 1px;
  /* padding-left: 13px;
  padding-right: 13px; */
  padding: 0;
}
.sert {
  margin-top: -31px;

  flex-wrap: wrap;
}
.fer-5 span.tag-cu {
    font-weight: 600;
    background-color: #D3D3D3 !important;
    border-radius: 2.4px;
    border: none!important;
    color: #383838!important;
    font-size: 12px;
    display: inline-flex;
    cursor: default;
    padding: 10px!important;
    line-height: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    margin-bottom: 10px;
}
.fer-5span.tag-cu {
  padding: 10px 15px !important;
}
.fer-5 span.tag-cu:hover {
  background-color: #b2b2b2!important;
  cursor: pointer;
}
.sert span {
  font-weight: 500;
}
.f42-contract a.ant-dropdown-link.adt-23.ant-dropdown-trigger {
  color: #999999;
  margin-left: 7%;
  border: 1px solid #D3D3D3;
  border-radius: 3px;
  padding: 2% 3%;
}

.dropdown-icon{
  position: absolute;
  top: 5px;
  right: 10px;
}

@media only screen and (max-width: 768px) {
    .dropdown-icon{
      position: absolute !important;
      /* top: 10px !important; */
      right: 10px !important;
    }
}

.job--title{
  font-style: normal !important;
  font-weight: normal !important;
  line-height: 20px;
  color: #383838 !important;
  padding-bottom: 2%;
  border-bottom: 0.76px solid #D3D3D3;
  margin-right: 7%;
  margin-left: 7%;
}

.clear-filter{
  font-style: normal;
font-weight: bold;
font-size: 14px;
line-height: 17px;
color: #6E6E6E;
}

.apply-filter{
  font-style: normal;
  font-weight: bold !important;
  font-size: 15px;
  line-height: 20px;
  color: #074369;
}

.apply-filter-mobile{
  font-style: normal;
  font-weight: bold !important;
  font-size: 13px;
  line-height: 20px;
  color: #FFFFFF !important;
  border: none;
  box-shadow: none;
  font-weight: 300;
  padding: 3px 12px;
  border-radius: 4px;
  height: 2.5rem;
}

.clear-filter-mobile{
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #074369;
}

.town23--mobile{
  display: none;
}
/* for mobile styles */

@media only screen and (max-width: 768px) {
  .jobprofile{
    width: 90%;
    margin: auto;
  }
  .savebtn.app{
    padding: .3125rem 1.25rem;
    border-radius: .375rem;
    right: 5%;
    margin-left: 5%;
  }
  .job-rate{
    position: relative;
    top: 11%;
  }
  .town23--desktop{
    display: none;
  }
  .town23--mobile{
    display: block;
  }
  .f42-contract a.ant-dropdown-link.adt-23.ant-dropdown-trigger {
    color: #999999;
    margin-left: 7%;
    border: 1px solid #D3D3D3;
    border-radius: 3px;
    padding: 1% 3%;
    width:81% !important;
  }
}