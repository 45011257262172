@media (min-width: 768px) {
  .mobile {
    display: none;
  }
  span.locate {
    display: flex;
  }

  span.locate p {
    margin-right: 10px;
  }
}

@media (max-width: 767px) {
  .mobilev.hide {
    display: none;
  }
  .desktop {
    display: none;
  }
  .candidate-form {
   
    padding: 30px 15px !important;
}
.ant-checkbox + span {
   padding-right: 0px; 
  padding-left: 8px;
  font-size: 13px;
}
  .header .ant-menu-horizontal {
    display: flex;
    flex-direction: column;
  }
  .header {
    /* padding-left: 16px; */
    /* padding-right: 16px; */
  }

  .first-section-header {
    min-width: 160px !important;
  }
  .ant-menu-horizontal .ant-menu-submenu:first-child {
    border-bottom: 0;
  }
  .deu-3 {
    padding: 0 16px;
  }
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover{
    color: transparent!important;
    border-bottom: none!important;
  }
  .ant-menu-horizontal
    .ant-menu-submenu:first-child
    .ant-menu-submenu-title
    span {
    color: #ffffff;
  }

  .ant-menu-horizontal .ant-menu-submenu:first-child .ant-menu-submenu-title {
    padding: 0;
    width: 27px;
    text-align: center;
  }

  .ant-menu-horizontal
    .ant-menu-submenu:first-child
    .ant-menu-submenu-title
    span:before {
    content: "";
    position: absolute;
    top: 12px;
    height: 3px;
    left: 0;
    width: 100%;
    background: #074369;
  }
  .ant-menu-horizontal
    .ant-menu-submenu:first-child
    .ant-menu-submenu-title
    span:after {
    content: "";
    position: absolute;
    bottom: 12px;
    height: 3px;
    width: 100%;
    left: 0;
    background: #074369;
  }

  .ant-menu-horizontal
    .ant-menu-submenu:first-child
    .ant-menu-submenu-title:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 3px;
    background: #074369;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }

  /* homepage */

  .cus-33 .container {
    padding: 50px 16px;
  }
  .heading-text span {
    font-size: 22px;
  }
  .heading-text h2 {
    font-size: 22px;
  }
  button.ant-btn.post-23 {
    height: 38px;
    padding: 0px 20px;
  }

  /* viewjob */
  .ant-row.dh41 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .ant-row.dh41 .ant-col.ant-col-xs-24 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .ant-row.dh41 .ant-col.ant-col-xs-24 .jobform {
    padding: 16px 16px !important;
  }
  .view-link {
    display: flex;
    flex-wrap: wrap;
    padding-top: 16px;
  }

  .view-link button {
    width: 50%;
    margin-bottom: 7px;
    text-align: left;
  }

  .heading-text {
    flex-direction: column;
    align-items: center;
  }

  .butn-4 {
    justify-content: center;
  }

  /* Postjob */
  .ratejob {
    margin-bottom: 26px;
  }
  .post-description {
    display: none;
  }

  .jobform button.ant-btn {
    margin-bottom: 10px;
    margin-right: 0px !important;
  }

  .butn-4 {
    display: flex;
    flex-direction: column;
  }

  /* footer */

  .ftext {
    font-size: 11px;
  }

  /* Search Page */
  .ant-row.ant-form-item.chlist {
    margin-top: 20px;
  }
  .active-45 .ant-select-show-search {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0px;
    width: auto !important;
  }
  .search-121 {
    padding: 0px 16px;
  }
  .sert {
    margin-top: 1rem;
  }
  .content-4 {
    margin-top: 10px;
  }
  .search-121 {
    padding: 0px 16px;
  }

  .search-box {
    justify-content: flex-start;
  }

  .filt-btn {
    margin-right: 12px;
  }

  .visi-67 .ant-slider {
    max-width: calc(100% - 20px);
    margin: 0 auto;
  }
  .search-box {
    margin-top: 0;
  }

  .search-box h4 {
    display: none;
  }
  .active-45 .ant-select.ant-select-single.ant-select-show-arrow {
    margin-bottom: 20px;
  }

  .content-4 > span.det-4.mobile {
    width: 50%;
    text-align: right;
  }
  .search-box .search-input {
    width: 100%;
    margin-bottom: 15px;
  }

  .content-4 {
    flex-wrap: wrap;
  }
  span.locate {
    flex-wrap: wrap;
  }

  span.locate p {
    width: 100%;
  }
  span.locate {
    flex-wrap: wrap;
  }

  span.locate p {
    width: 100%;
  }

  span.locate p {
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 10px;
  }
  .content-4 > p:first-child {
    width: 50%;
    margin-right: 0;
  }
  span.det-4 {
    font-size: 12px;
  }

  span.locate {
    display: flex;
    white-space: nowrap;
    width: 50%;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .displaybtn {
    display: flex;
  }
  .guest-34 {
    margin-bottom: 30px;
  }
  .page-33.fitl-5 {
    padding: 20px;
  }
  .detail-66 p {
    margin-bottom: 20px;
  }
  .guest-34 {
    padding: 10px 11px !important;
  }
  .candidate-22.mobile svg {
    color: #c5c3c3 !important;
    font-size: 12px;
    font-weight: 800;
  }
  .candidate-profile {
    padding-bottom: 0;
  }
  .joining {
    text-align: left!important;
    margin-top: 20px;
}

.cl-btn {
  justify-content: space-between;
}

span.per-23 {
  
  font-size: 12px!important;
}

.design-sp span {
  
  white-space: unset!important;
 
}
.search-item h4 {
 
  white-space: nowrap;
}
.cl-btn button {
  padding: 5px;
}
  /* viewcandidate Page */

  .des-34 {
    padding: 0px 16px;
  }
  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0px;
  }
  .profile-skill {
    flex-wrap: wrap;
  }
  .ant-row.dus-45 {
    margin-left: -16px;
    margin-right: -42px;
  }

  .ant-row.dus-45 .ant-col {
    padding: 0 !important;
  }
  .gud-34 {
    margin: 0 !important;
  }
  .ant-row.cuts-12 {
    margin: 0 !important;
  }
  .pts-5 .ant-card-body {
    padding: 24px 10px;
  }
  span.per-23 {
    font-weight: 500;
    color: #000;
    white-space: nowrap;
    font-size: 10px;
  }
  button.primary.dd-12 {
    background-color: #fff;
    border: none;
    outline: none;
    padding: 0;
    color: #1f6f99;
    min-height: auto!important;
    line-height: initial;
    border-radius: unset;
}  
.coin-img img {
  width: 90px;
  height: 90px;
}
.img-second img {
 
  display: none;
}
.std-1 h3 {
 
  font-size: 17px!important;
  
}

.switch h4 {
 
  font-size: 23px;
  
}
.img-second {
 
  margin-bottom: 0px!important;
}
.dd-4 {
  text-align: center;
}

.dd-4 div {
  max-width: 250px;
  margin: 0px auto;
  text-align: left;
}
ul.list-yu li {
  font-size: 12px;
  font-weight: 100;
  margin-top: 3px;
}
.fo-text span {
  color: #999999;
  white-space: nowrap;
  font-size: 11px;
}
.footer-2 {
 
  padding: 5px;
}
.std-1 h3 {
 
  margin-top: 59px!important;
}
ul.list-yu {
  padding: 0;
}
.switch p {
  font-size: 15px!important;
 
}
.std-1 h3 {
  margin-bottom: 50px;
  margin-top: 26px!important;
}


.it-card {
 
  margin: 0 auto!important;
}
.opening-text {
  padding: 0px!important;
}
.locat-45 h4 {
  font-size: 25px!important;
  
  padding-left: 0px!important;

}
h3.mobile.small-re {
  position: relative;
}
.gus-12 img {
  position: unset!important;
  left: 0!important;
  transform: unset!important;
  width: 100%!important;
}
.main-heading h3 {
  
  font-size: 20px;
 
}
.cg {
  
  padding: 20px!important;
}
h3.mobile.small-re:before {
  content: "";
  width: 171px;
  position: absolute;
  height: 2px;
  left: 38%;
  top: 100%;
  background-color: #000;
}
.dyf {
  padding-left: 15px!important;
  padding-right: 15px!important;
}
.cvn-2 {
 
  margin-bottom: 30px;
}
span.des-rt {
 
  padding: 10px 5px 10px 20px;
  margin-right: 0px;
}
.features {
  padding: 30px 40px;
}

button.ant-btn.scus-2 {
  padding: 2px 16px;
  border-width: 1px;
  margin: 0px 7px 10px;
}

button.ant-btn.scus-2 span {
  font-size: 12px!important;
}
button.ant-btn.scus-filled {
  padding: 7px 30px;
}

.gtt {
  padding: 0!important;
  width: 100%;
}

button.ant-btn.scus-filled span {
  font-size: 14px!important;
}
.dd-4 {
  padding-left: 6%;
}
.home-page {

  background-position: 112px 6%;
  
  background-size: 200%;
  
}
.locat-45 h6 {
  font-size: 17px!important;
}
.gtt {
  text-align: center!important;
}
.std-1 h3 {
  font-size: 19px;
  text-align: center;
}
.coin-img img {
  margin-top: 30px;
}
.switch-button button {
 
  padding: 3px 30px;
  
}
.locat-45 p {
  font-size: 15px!important;
}
.it-card p {
  font-size: 15px!important;
 
}
.it-card button {
 
  padding: 4px 40px!important;
}
.home-page {
  max-width: unset!important;
 
  background-image: url(../assets/Group\ 41_mob.png);
  background-position: right 80px;
  background-repeat: no-repeat;
  background-size: 50%;
  box-shadow: 0 0 2px 0px #adadad;
}
.opening-text {
    padding: 0px!important;
    margin-bottom: 50px;
}
button.ant-btn.homesign.ant-btn-primary {
  border: unset;
  border-radius: unset;
  box-shadow: none;
  padding: 0!important;
}
button.ant-btn.homesign.ant-btn-primary span {
  font-weight: 400;
}


/****************** candidate profile ****************/

.contract-12.Postjob {
  padding: 0px 16px!important;
}




/****************** jobddescription modal****************/

.job-d .ant-modal-wrap, .ant-image-preview-wrap {
  
  right: 42%;
 
}







/****************** jobdetail****************/


h2.mb-3 {
  margin-bottom: 20px;
  
}

/****************** header****************/
button.primary.dd-12 {
  padding: 0px!important;
  font-weight: 400;
}

/****************** Joblist popconfirm****************/

.ant-popover-content {
  margin: 0px 10px;
}










}

@media (max-width: 340px) {
  .candi-head {
    font-size: 12px;
  }
  .gud-34 {
    margin: 0 !important;
  }
  .ant-row.cuts-12 {
    margin: 0 !important;
  }
}

.sert {
  margin-top: 1rem;
}
.profile-skill {
  flex-wrap: wrap;
}

.detail-66 p {
  margin-bottom: 20px;
}
@media (max-width: 374px) {

  .switch p {
    font-size: 13px!important;
}
.switch h4 {
  font-size: 19px;
}
ul.list-yu li {
  font-size: 11px;
  font-weight: 100;
  margin-top: 5px;
}

}

.ant-input{
    box-sizing: border-box;
    margin: 0;
    font-variant: tabular-nums;
    list-style: none;
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    line-height: 2;
    background-color: #fff;
    background-image: none;
    /* border: 1px solid #fff; */
    border-radius: 4px;
    transition: all 0.3s;
}

.posted-time{
font-style: normal;
font-weight: normal;
font-size: 12px;
line-height: 16px;
color: #666666;
margin-top: 10%;
display: inline-block;
}

.filter-img{
  display: none;
  background-color: #ffffff;
  padding: 6px;
  box-shadow: 0px 0px 1px 1px rgb(0 0 0 / 10%);
  border-radius: 4px;
  cursor: pointer;
}

.btn-read-more{
  color: #074369;
  font-weight: bold;
  border-bottom: 1px solid #094369; 
  cursor: pointer;
  display: inline;
  /* position: absolute;
  bottom: 5%; 
  right: 1%; */
}

@media only screen and (max-width: 768px){
  .posted-time{
    margin-top: 0;
    display: block;
  }
  .posted-time--desktop{
    display: none;
  }
  .filter-img{
    display: block;
    margin-top: 0;
  }
}