.profile-skill svg {
    margin-top: 5px;
}
button.ant-btn.editbtn {
    padding: 4px 35px;
    height: 38px;
    /* background: #1f6f99!important; */
    background: #074369!important;
    border-color: #074369!important;
    border-radius: 4px;
}
.candidate-form {
    border: 1px solid #cec7c7;
    background-color: #ffff;
    max-width: 100%;
    padding: 30px 45px !important;
    border-radius: 10px;
}
.pace {
    margin-bottom: 20px;
}

.em-23 span {
    display: flex;
}
.title-name {
    font-weight: 600;
    color: #000;
}
.contractor h2 {
    margin-bottom: 20px;
}
.set-space.ant-breadcrumb {
    margin-bottom: 15px;
}
.profile-skill {
    display: flex;
}
.profile-skillspan.tag-cu {
    padding: 10px 15px !important;
}
span.ant-breadcrumb-link {
    cursor: pointer;
}
