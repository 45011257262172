.list-df p {
    color: #000;
    font-weight: 500;
}
.designation.dms {
    height: auto;
    margin-bottom: 7px;
}
.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 80px 0px 80px;
  }
  .heading-text {
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
  }
  span.doc-3 {
    display: inline-block;
    vertical-align: middle;
}

span.doc-3 button {
    line-height: normal;
    margin: 1px!important;
}
  .heading-text h2 {
    font-weight: 700;
    font-size: 25px;
  }
  .cus-33 .container {
    padding: 50px 0px;
  }
  .designation svg {
    font-size: 12px;
    color: #808080;
  }
  .heading-text span {
    color:#1f6f99;
    font-weight: 700;
    font-size: 25px;
  }
  button.ant-btn.ered.ant-btn-link {
    /* color: #1f6f99; */
    color: #074369 !important;
    margin-top: 5px;
  }
  .candidate-22 {
    display: flex;
    justify-content: space-between;
  }
  .designation {
    border: 1px solid #dcd6d6;
    background-color: #fff;
    padding: 20px 20px;
    height: 100%;
    border-radius: 10px;
  }

  .candidate-22 span {
    font-weight: 700;
    color: #000;
    white-space: nowrap;
  }
  .designation p {
    /* margin: 10px 0px; */
    word-break: break-word;
  }
  .candidate-22 h4 {
    font-weight: 700;
    color: #000;
  }
  span.state-33 {
    font-size: 12px;
    color: #808080;
    
  }
  .view-link {
    display: flex;
    justify-content: space-between;
  }
  .view-link button {
    border: none;
    background-color: #fff;
    color:#1f6f99;
    font-weight: 400;
    outline: none;
    cursor: pointer;
    padding: 0px;
  }
  .page-33 {
    display: flex;
    justify-content: flex-end;
    padding-top: 100px;
  }
  button.ant-btn.post-23 {
    background-color: #1f6f99 !important;
    height: 41px;
    padding: 0px 23px;
    border-color: #1f6f99;
  }
  
  .page-33 ul.ant-pagination.mini li.ant-pagination-options {
    float: left;
    position: relative;
  }
  
  .page-33 ul.ant-pagination.mini li.ant-pagination-options:after {
    content: "Candidates per page";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: calc(100% + 10px);
    white-space: nowrap;
  }
  button.linkbtn {
    border: none;
    background: transparent;
    outline: none;
    text-transform: uppercase;
  }
  .designation ol {
    padding-left: 10px;
  }
  .page-33.fitl-5.chgd {
    border: none!important;
    background-color: #EFF2F6!important;
}
.design-sp.detail-66.dss-2 {
  margin-top: 15px;
}

.designation.dms {
  height: 100%;
  }
  

  button.ant-btn.ant-btn-primary.ant-btn-sm {
    background-color: #1f6f99;
    border-color: #1f6f99;
}

button.ant-btn.ant-btn-sm:hover {
  border-color: #1f6f99;
  
}

.color-dark-blue{
  color: #074369;
}

.load-more-wrapper{
  margin-top: 30px;
  margin-bottom: 30px;
  text-align: -webkit-center;
}
.massage-load-more{
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #335A8D; 
  width: fit-content; 
  height: 23px;
  border-bottom: 1px solid #335A8D;   
}

@media only screen and (max-width: 762px) {
  .space-br{
    display: none;
  }
}