button.primary {
    background-color: #fff;
    border: 1px solid #1f6f99;
    outline: none;
    padding: 4px 10px;
    color: #1f6f99;
    min-height: auto!important;
    line-height: initial;
    border-radius: 20px;
    font-weight: 600;
    cursor: pointer;
}
button.ant-btn.homesign.ant-btn-primary {
    background-color: #fff!important;
    border-color: #1f6f99!important;
    border-radius: 20px;
    color: #1f6f99!important;
    padding: 5px 40px!important;
    height: auto !important;
    font-weight: 600;
}
button.ant-btn.homesign.ant-btn-primary:hover{
    background-color: #1f6f99!important;
    color: #fff !important;
}
button.primary:hover{

    background-color: #1f6f99;
    color: #fff;


}
.first-section-header img {
    width: 175px;
    height: auto;
    /* background-color: pink; */
    position: relative !important;
    /* right: 5%; */
}

li.ant-menu-item.ant-menu-item-only-child a {
    color: #1f6f99;
}
.first-section-header img:first-child {
    width: 35px;
}
.ant-menu-horizontal > .ant-menu-item a:hover {
    color: #1b8bc5;
}