.candi-head {
  display: flex;
  justify-content: space-between;
}
.content-4 {
  display: flex;
  margin-bottom: 15px;
}

.content-4 > p {
  margin-right: 10px;
  color: #000;
}
.details-7 {
  text-align: end;
}
.displaybtn button {
  border: none;
  box-shadow: none;
  padding: 0px 10px 0px 0px;
  margin-top: 8px;
  color: #1f6f99;
}

/* .location p{
  display: flex;
  justify-content: space-between;
} */

.location{
  position: relative;
  bottom: 4px;
}
.location-duration{
  display: flex;
  justify-content: space-between;
}

.location-text{
  display: inline-block;
  width: 50%;
}

.skit {
  display: flex;
  margin-top: 11px;
  margin-bottom: 1.5rem;
}
.fer-5 {
  display: flex;
  flex-wrap: wrap;
  max-width: 635px;
  width: 100%;
  margin: 0px auto;
  padding-left: 7%;
}
.state-33 .anticon.anticon-environment {
  margin-right: 10px;
  font-size: 14px;
}
.detail-66 p {
  margin-bottom: 15px;
}
button.ant-btn.sst-4.ant-btn-primary {
  background-color: #ffffff;
  color:#1f6f99;
  border-color: #ffffff;
  height: 40px;
  padding: 0px 20px;
  box-shadow: 0 2px 10px 1px rgb(142 147 148 / 40%), 0 1px 2px rgb(101 108 109 / 25%);
}
button.ant-btn.ant-btn-ghost {
  background-color: #a5a3a7;
  border-color: #a5a3a7;
  height: 40px;
  padding: 0 26px;
}

.displaybtn {
  margin-top: 12px;
}
.detail-66 span {
  border: 1px solid;
  border-radius: 20px;
  padding: 2px 12px;
  margin-right: 10px;
  color: #757575;
  font-weight: bold;
}
button.ant-btn.ant-btn-ghost {
  background-color: #a5a3a7;
  border-color: #a5a3a7;
}
.search-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.guest-34 ol {
  padding: 0px 0px 0px 10px;
}
.filt-btn button {
  background-color: transparent !important;
  color: #1f6f99!important;
  border: none;
  box-shadow: none;
  font-weight: 300;
}
.filt-btn-mobile button {
  background-color: #074369 !important;
  color: #FFFFFF !important;
  border: none;
  box-shadow: none;
  font-weight: 300;
  border-radius: 4px;
}

/* a:hover {
  color: #000;
}
a{
  color:#000;
} */
button.ant-btn.search {
  margin-left: 0px;
  background-color: #074369;
  color: #fff;
  border-radius: 4px;
  padding: 3px 20px;
  font-weight: 600;
}
.heading df-4 a.ant-dropdown-link.adt-23.ant-dropdown-trigger {
  color: #000;
}
.ant-slider-track {
 
  background-color: #074369;
  
}
.ant-slider-handle.ant-tooltip-open,.ant-slider-handle.ant-tooltip-open:focus {
  border-color: #074369;
}
.ant-slider-handle {
  border: solid 2px #074369;
}
.ant-dropdown-menu-item:hover, .ant-dropdown-menu-submenu-title:hover {
  background-color: #f5f5f5;
  color: #1f6f99;
}
span.tag-cu {
  font-size: 12px;
  color: #1f6f99!important;
  border: 1px solid #1f6f99!important;
  background-color: #fff!important;
  border-radius: 20px;
  padding: 3px 12px;
  margin-right: 10px;
  font-weight: 600;
}
button.ant-btn.sst-4.ant-btn-primary:hover {
  box-shadow: 0 2px 10px 1px rgb(54 72 76 / 40%), 0 1px 2px rgb(68 82 84 / 25%);
}
button.ant-btn.ant-btn-ghost:hover {
  box-shadow: 0 1px 6px rgb(88 101 103 / 35%);
}

.search-input {
  display: flex;
  align-items: center;
  width: 70%;
  padding-top: 0.6%;
}

.details-7 span {
  font-weight: 600;
}
.guest-34 {
  border: 1px solid #dcd6d6;
  background-color: #fff;
  padding: 10px 17px;
}
.post-6 span {
  font-size: 11px;
  border: 1px solid #1f6f99;
  border-radius: 20px;
  padding: 2px 5px;
  margin-right: 9px;
  color: #1f6f99;
  background-color: #fff;
}
.post-6 {
  margin-bottom: 20px;
}
.active-45 .ant-select-show-search {
  width: 300px !important;
  margin-bottom: 40px;
}
.guest-34 p {
  margin-bottom: 15px;
  margin-top: 10px;
}
.ant-card.bod-45.ant-card-bordered {
  border: 1px solid #dcd6d6;
}
.delete-link {
  display: flex;
  justify-content: space-between;
}

.delete-link button {
  border: none;
  box-shadow: none;
  background-color: #fff;
  outline: none;
}
button.vie-34 {
  color: #1f6f99;
}

.bod-45 .ant-card-body {
  padding: 24px 35px;
}
/* .search-item {
  
    margin-bottom: 50px;
    margin-top: -38px;
} */
.search-item h4 {
  line-height: 25px;
  margin-right: 20px;
}
.viewcandidate h2 {
  font-weight: 700;
  font-size: 25px;
  margin-top: 20px;
}
.visi-67 {
  transition: none !important;
  overflow: hidden !important;
}
.visi-67 * {
  transition: none !important;
}
.candidateprofile h2 {
  margin-top: 20px;
}
.ant-row.ant-form-item.chlist span {
  color: #e0d8d8 !important;
  font-weight: 400;
}
.recent-search ul {
  padding: 0;
  list-style: none;
}
.jobs ul {
  padding: 0;
  list-style: none;
}
.jobsearch h2 {
  margin-top: 20px;
  margin-bottom: 30px;
}
.jobs p {
  color: #1f6f99;
  font-weight: 600;
  font-size: 15px!important;
}
.search-item.pop-sp {
  margin-top: -59px;
}
.jobsearch {
  margin-bottom: 30px;
  padding-top: 25px;
}
.search-121 {
  box-shadow: 0 0 2px 0px #adadad;
  background-color: #EFF2F6;
}

.job--title{
  font-style: normal !important;
  font-weight: normal !important;
  line-height: 20px;
  color: #383838 !important;
  padding-bottom: 2%;
  border-bottom: 0.76px solid #D3D3D3;
  margin-right: 7%;
  margin-left: 7%;
}

.filter-img{
  display: inline-block;
  margin-left: 7%;
  margin-top: 11%;
}

.filter-block--display{
  padding-top: 9%;
  padding-left: 7%;
}

.filter-block--text{
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  display: inline-block;
  margin-left: 4%;
  color: #999999;
}

.jobs-Filters{
  margin-top: 13%;
  background-color: #fff;
  border-radius: 10px;
}

.skit--mobile{
  display: none;
}

.sert-mobile{
  display: none !important;
}

.slider-auto{
  margin: 0px 5%;
  padding: 21px 10px;
}

.filter-result-block{
  background-color: #074369; 
  height: 4.6875rem; 
  width: 100%; 
  padding-left: 7%; 
  padding-top: 3%;
  display: none !important;
}
.filter-result-text{
  font-size: 16px;
  color: white;
  font-weight: bold;
  margin-left: 3%;
}

.sert-desktop{
  margin-bottom: -10%;
}

@media only screen and (max-width: 768px){
  .jobsearch{
    margin-left: 2%;
  }
  .filter-result-block{
    display: block !important;
  }
  .job-heading{
    margin-left: 5%;
    font-size: 1.125rem;
  }
  .location-text{
    font-size: .75rem;
    margin-left: 2%;
  }
  .location-duration{
    display: block;
  }
  .duration{
    font-size: .75rem;
    color: #666666;
    display: block;
  }
  button.ant-btn.search {
    height: 34px !important;
    font-size: 12px;
    padding: 3px 13px;
    position: absolute;
    right: 19%;
  }
  .search-input{
    width: 80%;
    margin-bottom: 15px;
    margin: auto;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .search-box{
    margin: 0 6% 0 5%;
  }
  .search-input input{
    width: 83%;
  }
  /* job filter section hiding on mobile view*/
  .jobs-Filters{
    /* display: none; */
  }
  .skit--mobile{
    display: block;
  }
  .fer-5{
    margin-left: 5%;
    padding: 0;
  }
  .filter-block--display{
    display: none;
  }
  .job--title{
    border-bottom: none;
    font-weight: bold !important;
    color: #666666 !important;
  }
  .sert-desktop{
    display: none !important;
  }
  .sert-mobile{
    display: flex !important; 
  }
  .slider-auto{
    margin-right: 10%;
  }
}