@import "https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap";
body {
  margin: 0;
  font-family: "Poppins", sans-serif !important;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.contractjob a.ant-dropdown-link.ant-dropdown-trigger {
  margin-left: 11px;
}
.ratejob a.ant-dropdown-link.ant-dropdown-trigger {
  margin-left: 11px;
}
.contractjob {
  margin-top: 12px;
}

button.Button__button___vS7Mv {
  color: #fff;
  background: #1f6f99;
  background-color: #1f6f99!important ;
}
:root {
  --amplify-primary-color:#1f6f99 ;
  --amplify-primary-tint: #ff7359;
  --amplify-primary-shade: #e0573e;
}

.d-flex {
  display: flex;
}

.justify-content-center {
  justify-content: center;
}