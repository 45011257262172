/******************Jobdetail index *************/

.job-header {
    background: #f1f3f3;
    padding: 20px 0;
}
.job-header h1 {
    font-size: 30px;
    font-weight: 400;
    margin: 0 130px 11px 0;
}
.job-header .job-attributes li {
    margin-right: 10px;
}
.list-inline>li {
    display: inline-block;
    padding-left: 5px;
    padding-right: 5px;
}
.job-header .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0px;
}
.job-header span.anticon {
    margin-right: 10px;
}

ul.list-inline.job-attributes {
    padding: 0px;
}
.jobds-67 {
    padding: 25px 0 0!important;
}

.job-det  .count span {
    display: inline-flex;
  
}
.job-det .ant-checkbox-wrapper + .ant-checkbox-wrapper{

    margin-left:0px!important;
}
.job-det  label.ant-checkbox-wrapper.ant-checkbox-wrapper-checked {
    display: flex;
}
.cuj-8 {
    padding-left: 16px;
}
.job-info {
    border: 1px solid #cec7c7;
    background-color: #ffff;
    max-width: 100%;
    padding: 30px 30px !important;
    border-radius: 10px;
}
.apply-info {
    border: 1px solid #cec7c7;
    background-color: #ffff;
    max-width: 100%;
    padding: 30px 20px !important;
    border-radius: 10px;
}
.dh-555 {
    margin: 0px!important;
}
.job-info ul {
    padding: 0px 20px;
}
.jo-ti h2 {
    margin-bottom: 37px!important;
    font-weight: 600;
    min-height: 33px;
}
.jo-ti p {
    margin-bottom: 20px;
    min-height: 18px;
}
h2.mb-3 {
    margin-bottom: 37px;
    font-weight: 600;
}

.hideit {
    visibility: hidden;
    position: absolute;
}


.apply-info .ant-checkbox-checked::after {
    border: none!important;
    
}

.job-content-main {
    display: flex;
}
.info-content {
    order: -1;
}
@media (min-width:961px) {
    .info-content {
        order: 1;
    }
}

.mask-phone {
    box-sizing: border-box;
    margin: 0;
    font-feature-settings: "tnum";
    font-variant: tabular-nums;
    list-style: none;
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    padding: 4px 11px;
    /* color: rgba(0, 0, 0, 0.85); */
    font-size: 14px;
    line-height: 2;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    transition: all 0.3s;
}

.mask-phone:focus {
    border-color: #d9d9d9 !important;
    box-shadow: none;
    outline: 0;
}

.mask-phone:hover {
    border-color: #1f6f99!important;
    border-right-width: 1px !important;
}

.mask-phone::placeholder {
    text-overflow: ellipsis;
    color: #c4c4c4;
}